<ng-container *ngIf="type === 'default'">
  <div class="card eventContainer {{ (calenderEvent.priority === 'high' ? 'danger' : calenderEvent.priority === 'low' ? 'success' : 'warning') }} d-flex flex-row mb-2">
    <!-- First Column -->
    <div class="d-flex flex-grow-1">
      <!-- Left colored border -->
      <div class="event-bar evb-styled"></div>

      <!-- Main content area -->
      <div class="ms-2 py-2 pe-2 flex-grow-1">
        <app-headline [title]="calenderEvent.name" [type]="'h6'" [class]="'event-title fw-bold mb-1'"></app-headline>
        <div class="mb-2">
          <app-content [style]="'calendarEventItem'">{{ calenderEvent.description }}</app-content>
        </div>

        <div class="d-flex align-items-center">
          <div class="flex-grow-1">
            <app-form-button [style]="'btn'" [text]="(calenderEvent.dateStart | date : 'short') ?? ''" [cssClass]="'btn-flat text-muted fs-verysmall no-cursor-pointer p-0'" [icon]="'schedule'" [iconPosition]="'left'"></app-form-button>
            <app-form-button *ngIf="calenderEvent.isEditable" (click)="participantsEvent()" [style]="'btn'" [text]="calenderEvent.participantsUserIDs.length + ' ' + ('PERSONS' | translate)" [cssClass]="'btn-link text-primary fs-verysmall px-1 py-0'" [iconClass]="'text-primary'" [icon]="'person'" [iconPosition]="'left'"></app-form-button>
            <app-form-button *ngIf="!calenderEvent.isEditable" [style]="'btn'" [text]="calenderEvent.participantsUserIDs.length + ' ' + ('PERSONS' | translate)" [cssClass]="'btn-link text-muted fs-verysmall no-cursor-pointer px-1 py-0'" [iconClass]="'text-muted'" [icon]="'person'" [iconPosition]="'left'"></app-form-button>
            <app-form-button *ngIf="calenderEvent.followUps" [style]="'btn'" [text]="calenderEvent.followUps +' ' + 'TIMES' | translate" [cssClass]="'btn-flat text-muted fs-verysmall no-cursor-pointer p-0'" [icon]="'update'" [iconPosition]="'left'"></app-form-button>
          </div>
        </div>
      </div>
    </div>

    <div *ngIf="calenderEvent.isEditable" class="d-flex border-start align-items-center p-2">
      <div class="dropdown">
        <button type="button" class="btn btn-link p-0 text-muted" data-bs-toggle="dropdown" aria-expanded="false">
          <app-icon-badge [style]="'notification'" [icon]="'more_horiz'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
        </button>
        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="eventManagement">
          <li><app-link (click)="shareEvent()" [title]="('SHARE' | translate)" [style]="'dropdown'">{{ ('SHARE' | translate) }}</app-link></li>
          <li><app-link (click)="editEvent()" [title]="('CALEDITEVENT' | translate)" [style]="'dropdown'">{{ ('CALEDITEVENT' | translate) }}</app-link></li>
          <li><app-link (click)="showDeleteOverlay()" [title]="('CALDELETEEVENT' | translate)" [style]="'dropdown'">{{ ('CALDELETEEVENT' | translate) }}</app-link></li>
        </ul>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="type === 'widget'">
  <div class="eventContainer {{ (calenderEvent.priority === 'high' ? 'danger' : calenderEvent.priority === 'low' ? 'success' : 'warning') }}">
    <div class="d-flex justify-content-between align-items-center mb-1 mt-3">
      <div class="event-details flex-grow-1 d-flex">
        <div class="event-bar"></div>
        <div class="ms-2">
          <app-headline [title]="calenderEvent.name" [type]="'h6'" [class]="'fw-bold mb-1 event-title'"></app-headline>
          <div class="mb-0">
            <app-content [style]="'calendarEventItem'" [cssClass]="'fs-verysmall'">{{ calenderEvent.description }}</app-content>
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex align-items-center">
      <div class="flex-grow-1">
        <app-form-button [style]="'btn'" [text]="(calenderEvent.dateStart | date : 'short') ?? ''" [cssClass]="'btn-flat text-muted fs-verysmall no-cursor-pointer p-0'" [icon]="'schedule'" [iconPosition]="'left'"></app-form-button>

        <app-form-button *ngIf="calenderEvent.isEditable" (click)="participantsEvent()" [style]="'btn'" [text]="calenderEvent.participantsUserIDs.length + ' ' + ('PERSONS' | translate)" [cssClass]="'btn-link text-primary fs-verysmall px-1 py-0'" [iconClass]="'text-primary'" [icon]="'person'" [iconPosition]="'left'"></app-form-button>
        <app-form-button *ngIf="!calenderEvent.isEditable" [style]="'btn'" [text]="calenderEvent.participantsUserIDs.length + ' ' + ('PERSONS' | translate)" [cssClass]="'btn-link text-muted fs-verysmall no-cursor-pointer px-1 py-0'" [iconClass]="'text-muted'" [icon]="'person'" [iconPosition]="'left'"></app-form-button>
      </div>

      <div class="justify-content-end" *ngIf="calenderEvent.isEditable">
        <div class="dropdown">
          <button class="btn btn-link p-0 text-muted" id="event-menu" data-bs-toggle="dropdown" aria-expanded="false">
            <app-icon-badge [style]="'notification'" [icon]="'more_horiz'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
          </button>
          <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-menu">
            <li><app-link (click)="shareEvent()" [title]="('SHARE' | translate)" [style]="'dropdown'">{{ ('SHARE' | translate) }}</app-link></li>
            <li><app-link (click)="editEvent()" [title]="('CALEDITEVENT' | translate)" [style]="'dropdown'">{{ ('CALEDITEVENT' | translate) }}</app-link></li>
            <li><app-link (click)="showDeleteOverlay()" [title]="('CALDELETEEVENT' | translate)" [style]="'dropdown'">{{ ('CALDELETEEVENT' | translate) }}</app-link></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</ng-container>
