import {Component, Input} from '@angular/core';
import {DatePickerModule, TimePickerModule} from "@syncfusion/ej2-angular-calendars";
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {LanguageService} from "../../services/language.service";
import {L10n} from "@syncfusion/ej2-base";

@Component({
  selector: 'app-form-time',
  standalone: true,
  imports: [
    TimePickerModule,
    DatePickerModule,
    ReactiveFormsModule
  ],
  templateUrl: './form-time.component.html',
  styleUrl: './form-time.component.scss'
})
export class FormTimeComponent {
  @Input() fCN: string = '';
  @Input() disabled: boolean = false;
  @Input() cssClass: string = '';
  @Input() placeholder: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() step: number = 15;

  constructor(
    private languageService: LanguageService
  ) {
    this.setSyncFusionTranslation();
  }

  private setSyncFusionTranslation(): void {
    L10n.load({
      [this.languageService.getLanguage()]: {

      },
    });
  }
}
