import {Component} from '@angular/core';
import {IconBadgeComponent} from "../../../shared/elements/icon-badge/icon-badge.component";
import {LinkComponent} from "../../../shared/elements/link/link.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {DialogCalendarEventComponent} from "../dialog-calendar-event/dialog-calendar-event.component";
import {DialogCalendarEventsComponent} from "../dialog-calendar-events/dialog-calendar-events.component";
import {ItemCalendarEventComponent} from "../item-calendar-event/item-calendar-event.component";
import {TYPE} from "../../../shared/types/types";
import {DialogCalendarParticipantsComponent} from "../dialog-calendar-participants/dialog-calendar-participants.component";

@Component({
  selector: 'app-calendar-widget',
  standalone: true,
  imports: [
    IconBadgeComponent,
    LinkComponent,
    TranslatePipe,
    DialogCalendarEventComponent,
    DialogCalendarEventsComponent,
    ItemCalendarEventComponent,
    DialogCalendarParticipantsComponent
  ],
  templateUrl: './widget-calendar.component.html',
  styleUrl: './widget-calendar.component.scss'
})
export class WidgetCalendarComponent {

  public calendarEventDialog: boolean = false;
  public calendarEventsDialog: boolean = false;
  public calendarParticipantsDialog: boolean = false;

  public calenderEvent: TYPE.CalendarEvent = {
    name: 'Urgent meeting',
    priority: 'high',
    description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
    dateStart: new Date(),
    timeStart: new Date(),
    timeEnd: new Date(),
    frequency: 'once',
    onDateTimeEnd: new Date(),
    type: 'virtual',
    participantsUserIDs: [108],
    participantsGroupIDs: [8,6],
    participantsMails: ['cj@humanstars.app'],
    followUps: 2,
    isEditable: true
  } as TYPE.CalendarEvent;

  // TODO the whole thing



}
