import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TYPE} from "../../../shared/types/types";
import {DialogModule} from "@syncfusion/ej2-angular-popups";
import {FormInputComponent} from "../../../shared/elements/form-input/form-input.component";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {DialogService} from "../../../shared/services/dialog.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {FormUserGroupMailComponent} from "../../../shared/elements/form-user-group-mail/form-user-group-mail.component";

@Component({
  selector: 'app-dialog-calendar-participants',
  standalone: true,
  imports: [
    DialogModule,
    FormInputComponent,
    HeadlineComponent,
    TooltipComponent,
    TranslatePipe,
    FormUserGroupMailComponent
  ],
  templateUrl: './dialog-calendar-participants.component.html',
  styleUrl: './dialog-calendar-participants.component.scss'
})
export class DialogCalendarParticipantsComponent implements OnInit, OnChanges {

  @Input() calenderEvent: TYPE.CalendarEvent = {} as TYPE.CalendarEvent;
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public calendarParticipantsForm: FormGroup = this.formBuilder.group({
    searchString: [''],
  });

  constructor(
    public dialogService: DialogService,
    private formBuilder: FormBuilder
  ) {
  }

  public ngOnInit(): void {

  }

  public ngOnChanges(changes: SimpleChanges): void {

  }

  public close(): void {
    this.visibleChange.emit(false);
  }

}
