import {Component, Input} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {DatePickerModule, DateTimePickerModule} from "@syncfusion/ej2-angular-calendars";

@Component({
  selector: 'app-form-date-time',
  standalone: true,
  imports: [
    DatePickerModule,
    ReactiveFormsModule,
    DateTimePickerModule
  ],
  templateUrl: './form-date-time.component.html',
  styleUrl: './form-date-time.component.scss'
})
export class FormDateTimeComponent {
  @Input() fCN: string = '';
  @Input() disabled: boolean = false;
  @Input() cssClass: string = '';
  @Input() placeholder: string = '';
  @Input() fG: FormGroup = new FormGroup({});
  @Input() step: number = 15;
}
