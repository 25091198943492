import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf, NgIf} from "@angular/common";

@Component({
  selector: 'app-step-header',
  standalone: true,
  imports: [
    NgForOf,
    NgIf
  ],
  templateUrl: './step-header.component.html',
  styleUrl: './step-header.component.scss'
})
export class StepHeaderComponent {

  @Input() steps: string[] = [];
  @Input() activeStep: number = 0;
  @Output() activeStepChange: EventEmitter<number> = new EventEmitter<number>();

  public setStep(activeStep: number): void {
    this.activeStep = activeStep;
    this.activeStepChange.emit(this.activeStep);
  }

}
