<ng-container *ngIf="!(loadingService.loadingIndicator$ | async)">
    <div class="icon-list-container position-relative">
        <div class="icon-list position-fixed top-0 end-0 p-1">
            <!-- Notification Bell -->
            <button type="button" class="btn btn-link p-1 text-muted position-relative" (click)="toggleNotificationWindow()">
                <span class="mts-icon">notifications</span>
                <span class="badge-dot position-absolute top-0 translate-middle p-1 bg-primary border border-light rounded-circle">
                    <span class="visually-hidden">New alerts</span>
                </span>
            </button>

            <!-- Message Icon -->
            <button type="button" class="btn btn-link p-1 text-muted position-relative" (click)="toggleChatNotificationWindow()">
                <span class="mts-icon">chat_bubble</span>
            <span class="badge-dot position-absolute top-0 translate-middle p-1 bg-primary border border-light rounded-circle">
                <span class="visually-hidden">New messages</span>
            </span>
            </button>

            <!-- Avatar Dropdown -->
            <div class="dropdown d-inline">
            <button type="button" class="btn btn-link p-1 text-muted dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                <div class="e-avatar e-avatar-circle e-avatar-xsmall">
                    <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                </div>
            </button>
            <ul class="dropdown-menu dropdown-menu-end">
                <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event, 'show-language')">Language</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Profile</a></li>
                <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event, 'show-notification-settings')">Notifications</a></li>
                <li><hr class="dropdown-divider"></li>
                <li><a class="dropdown-item" href="#">Logout</a></li>
            </ul>
            </div>

            <!-- Notification Window -->
            <div *ngIf="showNotifications" class="notification-window shadow-lg p-3 d-flex flex-column">
                <div class="d-flex">
                    <button ejs-button type="button" class="e-btn e-primary w-100 mx-1">New</button>
                    <button ejs-button type="button" class="e-btn e-outline w-100 mx-1">Read</button>
                </div>

                <div class="d-flex justify-content-between align-items-center mt-3">
                    <h6 class="mb-0">Notifications</h6>
                    <div class="dropdown">
                        <button class="btn btn-link p-0 text-muted" id="notifications-menu" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="mts-icon">more_vert</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-menu">
                            <li><a class="dropdown-item" href="#">Mark all as read</a></li>
                        </ul>
                    </div>
                </div>
                <div class="alert alert-warning d-flex align-items-center p-2" role="alert">
                    <span class="mts-icon mx-2">warning</span>
                    <div class="fs-verysmall">
                        You have custom notifications active and might not receive all notifications.
                        <a href="#" class="alert-link">Edit notification preferences.</a>
                    </div>
                </div>

                <!-- Dropdown List with Icon -->
                <div class="row justify-content-end">
                    <div class="col-md-6 small-filter-dropdown">
                        <ejs-dropdownlist [dataSource]="statusData" [fields]="fields" [placeholder]="'Select Filter'" [sortOrder]="'Ascending'" [value]="selectedValue" [valueTemplate]='valueTemplate' class="filter-dropdown" (change)="onFilterChange($event)" >
                            <ng-template #valueTemplate let-data="">
                                <div class="d-flex align-items-center text-muted">
                                    <span class="mts-icon p-1">filter_list</span>
                                    <span class='value fs-small'>{{data.text}}</span>
                                </div>
                            </ng-template>
                        </ejs-dropdownlist>
                    </div>
                  </div>
                <div class="custom-scrollbar">
                    <div class="d-flex">
                        <p class="fs-small">Today</p>
                    </div>

                    <!-- Notification List -->
                    <div class="card shadow-sm w-border rounded-3 mb-2 bell-notifications" (click)="handleBellCardClick($event)">
                        <div class="card-body">
                            <div class="d-flex-column">
                                <div class="d-flex align-items-start">
                                    <div class="d-flex e-avatar e-avatar-circle e-avatar-large">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                                    </div>

                                    <div class="col ps-2">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <span class="fs-small fw-bold me-2 text-primary">Stefan Dragosici</span>
                                            <span class="text-muted fs-verysmall fst-italic">posted at 13:43 15.04.2024</span>
                                        </div>

                                        <p class="fs-verysmall text-muted mb-1">
                                        Discussing the problems related to the...
                                        </p>

                                        <div class="row align-items-center gx-2">
                                            <div class="col-auto d-flex align-items-center">
                                                <span class="badge bg-primary rounded-pill">CHANNELS</span>
                                            </div>
                                            <div class="col-auto flex-grow-1 text-end">
                                                <div class="dropdown">
                                                    <button class="btn btn-link p-0 text-muted" id="recentNotification" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span class="mts-icon">more_horiz</span>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="recentNotification">
                                                        <li><a class="dropdown-item" href="#">Mark as read</a></li>
                                                        <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Delete</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Chat Notification Window -->
            <div *ngIf="showChatNotifications" class="chat-notification-window shadow-lg p-3 d-flex flex-column">
                <div class="d-flex justify-content-between align-items-center mb-3">
                    <h6 class="mb-0">Chat Notifications</h6>
                    <div class="dropdown">
                        <button class="btn btn-link p-0 text-muted" id="chat-notifications-menu" data-bs-toggle="dropdown" aria-expanded="false">
                            <span class="mts-icon">more_vert</span>
                        </button>
                        <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="chat-notifications-menu">
                            <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Delete all</a></li>
                        </ul>
                    </div>
                </div>

                <div class="custom-scrollbar">
                    <div class="d-flex">
                        <p class="fs-small">Today</p>
                    </div>

                    <!-- Chat Notification List -->
                    <div class="card shadow-sm w-border rounded-3 mb-2 chat-notifications" (click)="handleChatCardClick($event)">
                        <div class="card-body">
                            <div class="d-flex-column">
                                <div class="d-flex align-items-start">
                                    <div class="d-flex e-avatar e-avatar-circle e-avatar-large">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                                    </div>

                                    <div class="col ps-2">
                                        <div class="d-flex flex-wrap align-items-center">
                                            <span class="fs-small fw-bold me-2 text-primary">Stefan Dragosici</span>
                                            <span class="text-muted fs-verysmall fst-italic">posted at 13:43 15.04.2024</span>
                                        </div>

                                        <p class="fs-verysmall text-muted mb-1">
                                        Discussing the problems related to the...
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div id="app-test" class="main-container d-flex p-2 p-md-3" role="main" [attr.aria-label]="('DASHBOARDPAGE' | translate)">
        <div class="inner-container w-100 h-100 d-flex flex-column flex-lg-row overflow-hidden custom-scrollbar">
            <!-- Right Column for Logo -->
            <div class="col-xl-8 col-lg-7 col-md-12 d-flex flex-column justify-content-center align-items-center order-1 order-lg-3 custom-scrollbar p-sm-2 p-md-3 overflow-visible">
                <div class="scroll-wrapper w-100">
                    <div class="dsb-container d-flex flex-grow-1 flex-column w-100 mx-auto">
                        <!-- Headline that appears when columns are wrapping -->
                        <div class="d-flex flex-column justify-content-center align-items-center d-lg-none mb-3">
                            <!-- Centered Company Logo -->
                            <div class="dashboard-company-logo mb-4">
                                <img src="https://www.humanstars.app/app/uploads/2020/05/HUMANSTARSapp_RGB_gruen.png" alt="company logo" class="img-fluid"/>
                            </div>

                            <!-- Row with Two Equal Columns -->
                            <div class="d-flex align-items-center">
                                <div class="e-avatar e-avatar-circle e-avatar-xxlarge">
                                    <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile picture">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h4>Good morning, Clair</h4>
                                    <p class="m-0">Tuesday 26, Apr</p>
                                </div>
                            </div>
                        </div>
                        <!-- Top Section: Settings Icon -->
                        <div class="d-flex justify-content-end mb-3">
                            <button class="btn btn-link text-muted" (click)="onOpenDialog($event, 'show-dashboardicons-edit')">
                                <span class="mts-icon">tune</span>
                            </button>
                        </div>

                        <!-- Tab Content for Icons -->
                        <div class="tab-content">
                            <div class="tab-pane fade show active" id="page1" role="tabpanel">
                                <div class="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 justify-content-center g-4">
                                    <div class="dash-icon d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container bottom-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">calendar_view_day</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">82</span>
                                        </div>
                                        <div class="icon-text text-center">Wall</div>
                                    </div>
                                    <div class="dash-icon lightpurple d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container top-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">cycle</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Feedback</div>
                                    </div>
                                    <div class="dash-icon pink d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container bottom-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">rebase</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Workflow</div>
                                    </div>
                                    <div class="dash-icon orange d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container top-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">psychology</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Knowlidge Base</div>
                                    </div>
                                    <div class="dash-icon aqua d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container bottom-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">chat</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Chat</div>
                                    </div>
                                    <div class="dash-icon yellow d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container top-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">forum</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Community</div>
                                    </div>
                                    <div class="dash-icon blue d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container bottom-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">videocam</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Meetings</div>
                                    </div>
                                    <div class="dash-icon darkorange d-flex flex-column align-items-center" (click)="onFakeClick()">
                                        <div class="icon-container top-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">import_contacts</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Contacts</div>
                                    </div>
                                </div>
                            </div>
                            <div class="tab-pane fade" id="page2" role="tabpanel">
                                <div class="row row-cols-3 row-cols-sm-3 row-cols-md-4 row-cols-lg-4 justify-content-center g-4">
                                    <div class="dash-icon cyan d-flex flex-column align-items-center">
                                        <div class="icon-container bottom-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">tv</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">82</span>
                                        </div>
                                        <div class="icon-text text-center">TV</div>
                                    </div>
                                    <div class="dash-icon purple d-flex flex-column align-items-center">
                                        <div class="icon-container top-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">perm_contact_calendar</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Profile</div>
                                    </div>
                                    <div class="dash-icon darkgreen d-flex flex-column align-items-center">
                                        <div class="icon-container bottom-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">person</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Users</div>
                                    </div>
                                    <div class="dash-icon skyblue d-flex flex-column align-items-center">
                                        <div class="icon-container top-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">group</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Groups</div>
                                    </div>
                                    <div class="dash-icon red d-flex flex-column align-items-center">
                                        <div class="icon-container bottom-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">settings</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Settings</div>
                                    </div>
                                    <div class="dash-icon brightgreen d-flex flex-column align-items-center">
                                        <div class="icon-container top-left position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">folder</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Document Manager</div>
                                    </div>
                                    <div class="dash-icon asphalt d-flex flex-column align-items-center">
                                        <div class="icon-container bottom-right position-relative">
                                            <div class="background-layer"></div>
                                            <div class="foreground-layer">
                                                <div class="icon">
                                                    <span class="mts-icon">calendar_month</span>
                                                </div>
                                            </div>
                                            <span class="e-badge e-badge-primary e-badge-overlap e-badge-notification">2</span>
                                        </div>
                                        <div class="icon-text text-center">Calendar</div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Tab Navigation for Pagination -->
                        <div class="d-flex justify-content-center mt-3">
                            <ul class="nav nav-pills">
                                <li class="nav-item">
                                    <a class="nav-link active" id="page1-tab" data-bs-toggle="tab" href="#page1" role="tab">1</a>
                                </li>
                                <li class="nav-item">
                                    <a class="nav-link" id="page2-tab" data-bs-toggle="tab" href="#page2" role="tab">2</a>
                                </li>
                            </ul>
                        </div>

                        <!-- Settings -->
                        <div class="d-flex justify-content-end mt-3 mb-2">
                            <div class="dropdown">
                                <button class="btn btn-link p-0 text-muted" id="linkManagement" data-bs-toggle="dropdown" aria-expanded="false">
                                    <span class="mts-icon">tune</span>
                                </button>
                                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="linkManagement">
                                    <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event, 'show-all-links')">Manage Links</a></li>
                                    <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event, 'new-link')">Add Link</a></li>
                                </ul>
                            </div>
                        </div>

                        <!-- Links -->
                        <div class="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 justify-content-center g-2 p-2 p-sm-0 p-lg-2">
                            <div class="d-flex">
                                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                                    <div class="icon-background">
                                        <span class="mts-icon">link</span>
                                    </div>
                                    <span class="link-text">Covid langer Text damit</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                                    <div class="icon-background">
                                        <span class="mts-icon">link</span>
                                    </div>
                                    <span class="link-text">LEER</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                                    <div class="icon-background">
                                        <span class="mts-icon">link</span>
                                    </div>
                                    <span class="link-text">WM Tippspiel</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="link-card flex-grow-1" (click)="onFakeClick()">
                                    <div class="icon-background">
                                        <span class="mts-icon">link</span>
                                    </div>
                                    <span class="link-text">WM Tippspiel</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- Left Column for Form -->
            <div class="col-xl-4 col-lg-5 col-md-12 d-flex flex-column justify-content-center align-items-center order-2 order-lg-1">
                <div class="w-100 h-100">
                    <div class="dsb-container d-flex flex-column w-100 h-100 mx-auto">
                        <!-- Headline that appears when columns are NOT wrapping -->
                        <div class="d-none flex-column justify-content-center align-items-center d-lg-flex mb-3 p-2">
                            <!-- Company Logo -->
                            <div class="dashboard-company-logo mb-4">
                                <img src="https://www.humanstars.app/app/uploads/2020/05/HUMANSTARSapp_RGB_gruen.png" alt="company logo" class="img-fluid"/>
                            </div>

                            <!-- Row with Two Equal Columns -->
                            <div class="d-flex align-items-center">
                                <div class="e-avatar e-avatar-circle e-avatar-xxlarge">
                                    <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                                </div>
                                <div class="flex-grow-1 ms-3">
                                    <h5>Good morning, Clair</h5>
                                    <p class="m-0">Tuesday 26, Apr</p>
                                </div>
                            </div>
                        </div>
                        <div class="custom-scrollbar overflow-y-auto p-2">
                            <div class="calendar-event w-border card shadow-sm rounded-3 mb-2">
                                <div class="card-body">
                                    <!-- Header -->
                                    <div class="d-flex justify-content-between align-items-center mb-1">
                                        <h6 class="m-0">Event Calendar</h6>
                                        <div class="d-flex align-items-center">
                                            <button class="btn btn-link text-muted p-0 me-2">
                                                <div class="mts-icon">chevron_left</div>
                                            </button>
                                            <button class="btn btn-link text-muted p-0 me-3">
                                                <div class="mts-icon">chevron_right</div>
                                            </button>
                                            <button class="btn btn-link text-muted p-0 me-3">
                                                <div class="d-flex align-items-center">
                                                    <div class="mts-icon">event</div>
                                                    <span>Apr</span>
                                                </div>
                                            </button>
                                          
                                        </div>
                                    </div>

                                    <!-- Days of the Week -->
                                    <div class="d-flex justify-content-between align-items-center mb-2">
                                        <div class="text-center day-container">
                                            <small class="text-muted">Mon</small>
                                            <div class="fw-bold py-1">23</div>
                                            <div class="dots-no-bg">
                                                <span class="dot"></span>
                                            </div>
                                        </div>
                                        <div class="text-center day-container">
                                            <small class="text-muted">Tue</small>
                                            <div class="fw-bold py-1">24</div>
                                            <div class="dots-no-bg">
                                                <span class="dot bg-waring"></span>
                                                <span class="dot bg-success"></span>
                                            </div>
                                        </div>
                                        <div class="text-center day-container">
                                            <small class="text-muted">Wed</small>
                                            <div class="fw-bold py-1">25</div>
                                            <div class="dots-no-bg">
                                                <span class="dot bg-success"></span>
                                            </div>
                                        </div>
                                        <!-- Highlighted Current Day -->
                                        <div class="text-center current-day day-container">
                                            <small class="text-white">Thu</small>
                                            <div class="fw-bold text-white rounded px-2 py-1">26</div>
                                            <div class="dots-container-inverted">
                                                <span class="dot bg-danger"></span>
                                                <span class="dot bg-success"></span>
                                                <span class="dot bg-warning"></span>
                                            </div>
                                        </div>
                                        <div class="text-center day-container">
                                            <small class="text-muted">Fri</small>
                                            <div class="fw-bold py-1">27</div>
                                            <div class="dots-no-bg">
                                                <span class="dot bg-warning"></span>
                                                <span class="dot bg-danger"></span>
                                            </div>
                                        </div>
                                        <div class="text-center day-container">
                                            <small class="text-muted">Sat</small>
                                            <div class="fw-bold py-1">28</div>
                                            <div class="dots-no-bg">
                                                <span class="dot bg-danger"></span>
                                                <span class="dot bg-warning"></span>
                                                <span class="dot bg-success"></span>
                                            </div>
                                        </div>
                                        <div class="text-center day-container">
                                            <small class="text-muted">Sun</small>
                                            <div class="fw-bold py-1">29</div>
                                            <div class="dots-no-bg">
                                                <span class="dot bg-danger"></span>
                                                <span class="dot bg-warning"></span>
                                            </div>
                                        </div>
                                    </div>

                                    <!-- Options Menu for Days of the Week -->
                                    <div class="d-flex justify-content-end">
                                        <div class="dropdown">
                                            <button class="btn btn-link p-0 text-muted" id="event-calendar-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                                <span class="mts-icon">more_horiz</span>
                                            </button>
                                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-calendar-menu">
                                                <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event, 'new-event')">Add Event</a></li>
                                                <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event, 'show-event')">Show all Events</a></li>
                                            </ul>
                                        </div>
                                    </div>

                                    <!-- Events -->
                                    <div class="row eventContainer danger">
                                        <div class="d-flex justify-content-between align-items-center mb-1 mt-3">
                                            <div class="event-details d-flex">
                                                <div class="event-bar"></div>
                                                <div class="ms-2">
                                                    <h6 class="fw-bold event-title mb-1">Urgent meeting</h6>
                                                    <p class="fs-verysmall text-muted mb-0">
                                                        Discussing the problems related to the application bugs and clarifying the process of testing the application.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row align-items-center gx-2 ps-3">
                                            <div class="col-auto d-flex align-items-center me-3">
                                                <span class="mts-icon">schedule</span>
                                                <span class="fs-verysmall ps-1">10:00 - 11:30</span>
                                            </div>
                                            <div class="col-auto d-flex align-items-center me-3">
                                                <button class="btn btn-link text-muted d-flex align-items-center p-0" (click)="onOpenDialog($event, 'show-event-participants')">
                                                    <span class="mts-icon">person</span>
                                                    <span class="fs-verysmall ps-1 text-primary">2 Persons</span>
                                                </button>
                                            </div>
                                            <div class="col-auto flex-grow-1 text-end">
                                                <div class="dropdown">
                                                    <button class="btn btn-link p-0 text-muted" id="event-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span class="mts-icon">more_horiz</span>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="event-menu">
                                                        <li><a class="dropdown-item" href="#" (click)="onShareEvent()">Share</a></li>
                                                        <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event, 'new-event')">Edit</a></li>
                                                        <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Remove</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="card shadow-sm rounded-3 mb-2 w-border recent-notifications" (click)="handleRecentCardClick($event)">
                                <div class="card-body">
                                    <div class="d-flex-column">
                                        <div class="d-flex align-items-start">
                                            <div class="d-flex e-avatar e-avatar-circle e-avatar-large">
                                                <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                                            </div>

                                            <div class="col ps-2">
                                                <div class="d-flex flex-wrap align-items-center">
                                                    <span class="fs-small fw-bold me-2 text-primary">Stefan Dragosici</span>
                                                    <span class="text-muted fs-verysmall fst-italic">posted at 13:43 15.04.2024</span>
                                                </div>

                                                <p class="fs-verysmall text-muted mb-1">
                                                Discussing the problems related to the application bugs and clarifying the process of testing the application...
                                                </p>
                                                <div class="row align-items-center gx-2">
                                                    <div class="col-auto d-flex align-items-center">
                                                        <span class="badge bg-primary rounded-pill">CHANNELS</span>
                                                    </div>
                                                    <div class="col-auto flex-grow-1 text-end">
                                                        <div class="dropdown">
                                                            <button class="btn btn-link p-0 text-muted" id="recentNotification" data-bs-toggle="dropdown" aria-expanded="false">
                                                                <span class="mts-icon">more_horiz</span>
                                                            </button>
                                                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="recentNotification">
                                                                <li><a class="dropdown-item" href="#">Mark as read</a></li>
                                                                <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Delete</a></li>
                                                            </ul>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!-- App Store Buttons at the Bottom Right -->
    <div class="app-store-buttons d-none d-md-flex">
        <app-link [href]="'https://play.google.com/store/apps/details?id=com.maxtoolbox.blau'" [target]="'_blank'" [title]="'Google Play'">
        <img [ngSrc]="'/assets/images/logos/google.png'" [alt]="'Google Play'" [width]="134" [height]="40" [loaderParams]="{width: 134, height: 40}" priority />
        </app-link>
        <app-link [href]="'https://apps.apple.com/de/app/humanstars-employee-app/id1343223126'" [target]="'_blank'" [title]="'App Store'">
        <img [ngSrc]="'/assets/images/logos/apple.png'" [alt]="'App Store'" [width]="132" [height]="40" [loaderParams]="{width: 132, height: 40}" priority />
        </app-link>
    </div>

    <div class="dialogs">
        <!-- Dialog for New Event -->
        <ejs-dialog target=".dialogs" class="steps-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #newEventDialog header="" [position]='position' [isModal]="true" [allowDragging]="false" [width]="'70%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="false">
            <ng-template #header class="p-1">
                <div class="steps-list d-flex justify-content-between align-items-center w-100">
                    <!-- Numbers of steps before the current step -->
                    <div class="d-flex justify-content-start flex-shrink-0">
                        <div *ngFor="let step of steps; let i = index" [ngClass]="{
                            'active-step': i === currentStep,
                            'completed-step': i < currentStep,
                            'inactive-step': i > currentStep
                        }"
                        (click)="goToStep(i)">
                            <span *ngIf="i < currentStep" class="step-number d-flex justify-content-center align-items-center fs-small">
                              {{ i + 1 }}
                            </span>
                        </div>
                    </div>

                    <!-- Current step and title -->
                    <div class="d-flex flex-grow-1 active-step align-items-center">
                      <span class="step-number me-2 d-flex justify-content-center align-items-center fs-small">
                        {{ currentStep + 1 }}
                      </span>
                      <span class="step-title fs-small">{{ steps[currentStep].title }}</span>
                    </div>

                    <!-- Remaining steps numbers -->
                    <div class="d-flex justify-content-end flex-shrink-0">
                        <div *ngFor="let step of steps; let i = index" [ngClass]="{
                            'active-step': i === currentStep,
                            'completed-step': i < currentStep,
                            'inactive-step': i > currentStep
                        }"
                        (click)="goToStep(i)">
                            <span *ngIf="i > currentStep" class="step-number d-flex justify-content-center align-items-center fs-small">
                              {{ i + 1 }}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex h-100">
                    <!-- Left Column -->
                    <div class="col-md-4 col-12 d-none d-md-flex flex-column left-container h-100 p-3">
                        <div class="text-center mb-3">
                            <h5>Add Event</h5>
                        </div>

                        <!-- Scrollable Steps List -->
                        <div class="custom-scrollbar">
                            <ul class="steps-list list-unstyled">
                                <li *ngFor="let step of steps; let i = index"
                                    [ngClass]="{
                                        'active-step': i === currentStep,
                                        'completed-step': i < currentStep,
                                        'inactive-step': i > currentStep
                                    }"
                                    (click)="goToStep(i)">
                                <span class="step-number">{{ i + 1 }}</span>
                                <span class="step-title">{{ step.title }}</span>
                                </li>
                            </ul>
                        </div>

                        <!-- Save Button (Hidden on Mobile) -->
                        <div class="save-button-container text-center mt-3 d-none d-md-block">
                        <button class="e-btn e-primary w-100" (click)="save()">Save</button>
                        </div>
                    </div>

                    <!-- Right Column -->
                    <div class="col-md-8 col-12 d-flex flex-column flex-grow-1 right-container h-100 overflow-y-auto p-3">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <div class="d-flex align-items-center">
                                <h5 class="m-0">{{ steps[currentStep].title }}</h5>
                                <ejs-tooltip #helpTooltip [content]='tooltipContent' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                                    <span class="mts-icon cursor-pointer">help</span>
                                </ejs-tooltip>
                            </div>
                            <!--!!!!!!!!!!!!!THIS CLOSE BUTTON MUST BE WITH THESE CLASSES, BECAUSE ONLY BOOTSTRAP HAS SUCH BUTTON CLASS!!!!!!!!!!!!!-->
                            <button type="button" class="btn btn-close" aria-label="Close" (click)="closeDialog('new-event')"></button>
                        </div>

                        <!-- Scrollable Form Container -->
                        <div class="custom-scrollbar overflow-y-auto">
                            <form class="p-1 d-flex flex-column h-100">
                                <ng-container [ngSwitch]="currentStep">
                                    <ng-container *ngSwitchCase="0">
                                        <!-- Event Name-->
                                        <div class="d-flex align-items-center">
                                            <label class="pb-1 text-secondary fw-bold">Event Name</label>
                                            <span class="ms-1 text-secondary fs-verysmall">{{ eventNameValue.length }}/35 characters left</span>
                                        </div>
                                        <div class="mb-3">
                                            <ejs-textbox name="eventNameValue" [value]="eventNameValue" placeholder="Type Event Name here" aria-placeholder="Event Name" aria-labelledby="Event Name" (input)="onInputChange($event)" maxlength="35"></ejs-textbox>
                                        </div>
                                        <!-- Event Priority-->
                                        <div class="d-flex align-items-center">
                                            <label class="text-secondary fw-bold">Priority Level</label>
                                        </div>
                                        <div class="mb-3">
                                            <ejs-dropdownlist name="priorityTypes" [dataSource]="priorityTypes" [fields]="priorityFields" placeholder='Select Priority'>
                                                <ng-template #itemTemplate="" let-data="">
                                                    <span *ngIf="data.id == 'high'" class="text-danger">{{data.text}}</span>
                                                    <span *ngIf="data.id == 'important'" class="text-warning">{{data.text}}</span>
                                                    <span *ngIf="data.id == 'normal'" class="text-success">{{data.text}}</span>
                                                </ng-template>
                                            </ejs-dropdownlist>
                                        </div>
                                        <!-- Event Description-->
                                        <div class="d-flex align-items-center">
                                            <label class="pb-1 text-secondary fw-bold">Event Description</label>
                                            <span class="ms-1 text-secondary fs-verysmall">{{ eventDescriptionValue.length }}/100 characters left</span>
                                        </div>
                                        <div class="mb-3">
                                            <ejs-textarea [value]="eventDescriptionValue" placeholder='Type your description here' (input)="onTextareaChange($event)" maxLength="100" rows="2" cols="35" required=""></ejs-textarea>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="1">
                                        <!-- Date Section -->
                                        <div class="row">
                                            <div class="col-12">
                                                <label class="text-secondary fw-bold">Date</label>
                                            </div>
                                        </div>

                                        <div class="row g-2 mb-3">
                                            <div class="col-4 col-md-4 col-xl-4">
                                                <ejs-datepicker id="datePicker" [placeholder]="selectedDate" [value]="selectedDate" format="dd.MM.yyyy"></ejs-datepicker>
                                            </div>

                                            <div class="col-8 col-md-8 col-xl-6 d-flex align-items-center">
                                                <label class="me-2">from</label>
                                                <ejs-timepicker id="startTimePicker" value="13:15" format="HH:mm" step="15"></ejs-timepicker>

                                                <label class="mx-2">to</label>
                                                <ejs-timepicker id="endTimePicker" value="15:00" format="HH:mm" step="15"></ejs-timepicker>
                                            </div>
                                        </div>

                                        <!-- Frequency Section -->
                                        <div class="row mb-1">
                                            <label class="text-secondary fw-bold">Frequency</label>
                                            <div class="col-12">
                                                <div class="mb-2">
                                                    <ejs-radiobutton label="Only once" name="frequency" checked="true"></ejs-radiobutton>
                                                </div>

                                                <div class="mb-2">
                                                    <ejs-radiobutton label="Every" name="frequency"></ejs-radiobutton>
                                                    <div class="mt-2 form-check">
                                                        <div class="d-flex align-items-center mb-3">
                                                            <div class="col-4 col-md-3 me-2">
                                                                <ejs-numerictextbox format='###.##' value='10'></ejs-numerictextbox>
                                                            </div>
                                                            <ejs-dropdownlist id="frequencyTypes" [dataSource]="frequencyTypes" [value]="selectedFrequencyType" ></ejs-dropdownlist>
                                                        </div>

                                                        <div class="d-flex align-items-center">
                                                            <ejs-dropdownlist id="endCondition" (change)="onEndConditionChange($event)" [dataSource]="endConditions" [value]="selectedEndCondition" class="me-2"></ejs-dropdownlist>
                                                            <ng-container *ngIf="selectedEndCondition === 'End after'">
                                                                <div class="col-4 col-md-3 me-2">
                                                                    <ejs-numerictextbox format='###.##' value='10'></ejs-numerictextbox>
                                                                </div>
                                                                <ejs-dropdownlist id="eventType" [dataSource]="eventType" [value]="selectedEventType"></ejs-dropdownlist>
                                                            </ng-container>
                                                            <ng-container *ngIf="selectedEndCondition === 'End on'">
                                                                <ejs-datetimepicker id="endDateTime" [locale]="'de-DE'" format="dd.MM.yyyy HH:mm" step="15" [timeFormat]="'HH:mm'"></ejs-datetimepicker>
                                                            </ng-container>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="d-flex justify-content-end">
                                                    <!-- Button to generate events -->
                                                    <button type="button" class="e-btn e-secondary e-small my-1">Generate Events</button>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- Frequency Info Box Section -->
                                        <div class="row ps-md-3">
                                            <div class="form-check col-12">
                                                <div class="alert alert-info p-2">
                                                    <div class="d-flex align-items-center justify-content-between">
                                                        <div class="d-flex align-items-center">
                                                            <span class="mts-icon me-1">info</span>
                                                            <span class="fw-bold fs-verysmall me-1">{{ totalEvents }} events</span>
                                                            <span class="fs-verysmall">will be created at the following dates:</span>
                                                        </div>
                                                    </div>
                                                    <!-- Display the badges -->
                                                    <div class="mt-2 d-flex flex-wrap">
                                                        <!-- Display first few badges -->
                                                        <ng-container *ngFor="let event of displayedEvents">
                                                            <span href="#" class="badge bg-light text-primary mb-1 me-1 fw-normal">{{ event }}</span>
                                                        </ng-container>

                                                        <!-- Hidden badges -->
                                                        <ng-container *ngIf="showMore">
                                                            <ng-container *ngFor="let event of hiddenEvents">
                                                            <span href="#" class="badge bg-light text-primary mb-1 me-1 fw-normal">{{ event }}</span>
                                                            </ng-container>
                                                        </ng-container>
                                                    </div>
                                                    <div class="d-flex justify-content-end">
                                                        <button type="button" class="e-btn e-link p-0 fs-verysmall" (click)="toggleShowMore()">
                                                            {{ showMore ? 'Show Less' : 'Show More' }}
                                                        </button>
                                                    </div>
                                              </div>
                                            </div>
                                        </div>

                                        <!-- Type of Task Section -->
                                        <div class="row mb-3">
                                            <label class="text-secondary fw-bold">Type of Task</label>
                                            <div class="col-12">
                                                <!-- Physical Radio and Input Field -->
                                                <div class="d-flex align-items-center" style="height: 35px;">
                                                    <ejs-radiobutton
                                                        label="Physical"
                                                        name="taskType"
                                                        (change)="onTaskTypeChange('Physical')"
                                                        [checked]="taskType === 'Physical'"
                                                        cssClass="me-3">
                                                    </ejs-radiobutton>

                                                    <ng-container *ngIf="taskType === 'Physical'">
                                                        <ejs-textbox class="flex-grow-1" placeholder="Details for physical task" name="sdfsdf"></ejs-textbox>
                                                    </ng-container>

                                                </div>


                                                <!-- Virtual Radio and Input Field -->
                                                <div class="d-flex align-items-center" style="height: 35px;">
                                                    <ejs-radiobutton
                                                        label="Virtual"
                                                        name="taskType"
                                                        (change)="onTaskTypeChange('Virtual')"
                                                        [checked]="taskType === 'Virtual'"
                                                        cssClass="me-3">
                                                    </ejs-radiobutton>

                                                    <ng-container *ngIf="taskType === 'Virtual'">
                                                        <ejs-textbox class="flex-grow-1" placeholder="https://www.somewebsite.com" name="sdfsdf1"></ejs-textbox>
                                                    </ng-container>
                                                </div>

                                                <!-- Video Meeting Radio -->
                                                <div class="d-flex align-items-center" style="height: 35px;">
                                                    <ejs-radiobutton
                                                        label="Video Meeting"
                                                        name="taskType"
                                                        (change)="onTaskTypeChange('Video Meeting')"
                                                        [checked]="taskType === 'Video Meeting'">
                                                    </ejs-radiobutton>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngSwitchCase="2">
                                        <!-- User/Group and External Email-->
                                        <div class="row g-2 mb-1">
                                            <div class="col-6">
                                                <div class="d-flex-column">
                                                    <label class="text-secondary fw-bold">Add Group/User</label>
                                                    <ejs-dropdownlist
                                                    [dataSource]="usersAndGroups"
                                                    [fields]="userGroupFields"
                                                    [query]="userGroupQuery"
                                                    [allowFiltering]="true"
                                                    (filtering)="onFiltering($event)"
                                                    placeholder="Type group/user name">
                                                    </ejs-dropdownlist>
                                                </div>
                                            </div>

                                            <div class="col-6">
                                                <div class="d-flex-column">
                                                    <label class="text-secondary fw-bold">External Email</label>
                                                    <app-form-input [placeholder]="('EXTERNALEMAIL' | translate)" [iconBack]="'add_circle'" [iconClickType]="'back'" [fCN]="'external_email'" [fG]="loginForm"></app-form-input>
                                                </div>
                                            </div>

                                            <!-- Selected Participants -->
                                            <div class="d-flex align-items-center">
                                                <label class="text-secondary fw-bold">Selected Participants</label>
                                            </div>
                                            <div class="small-filter-dropdown d-flex justify-content-between align-items-center">
                                                <ejs-dropdownlist popupWidth='auto' [dataSource]="userFilterData" [fields]="userFilterFields" [placeholder]="'Select Filter'" [sortOrder]="'Ascending'" [value]="userFilterSelectedValue" class="filter-dropdown" (change)="onUserFilterChange($event)" >
                                                    <ng-template #valueTemplate let-data="">
                                                        <div class="d-flex align-items-center text-muted">
                                                            <span class="mts-icon p-1">filter_list</span>
                                                            <span class='value fs-small'>{{data.text}}</span>
                                                        </div>
                                                    </ng-template>
                                                </ejs-dropdownlist>
                                                <div class="dropdown">
                                                    <button class="btn btn-link p-0 text-muted" id="notifications-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span class="mts-icon">more_vert</span>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-menu">
                                                        <li><a class="dropdown-item" href="#">Select all</a></li>
                                                        <li><a class="dropdown-item" href="#">Notifications for selected</a></li>
                                                        <li><a class="dropdown-item" href="#">Disolve selected</a></li>
                                                        <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Remove selected</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="custom-scrollbar overflow-y-auto">
                                            <div class="card selected-user-group mb-2">
                                                <div class="d-flex align-items-center">
                                                    <div class="remove-icon-column d-flex align-items-center justify-content-center border-end">
                                                        <button class="e-btn e-link text-muted p-0" (click)="onOpenConfirmDelete($event)">
                                                            <span class="mts-icon">delete</span>
                                                        </button>
                                                    </div>

                                                    <div class="d-flex align-items-center px-1">
                                                        <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                                            <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                                        </div>
                                                    </div>

                                                    <div class="flex-grow-1 lh-1">
                                                        <ejs-tooltip content='Tooltip content1'>
                                                            <h6 class="mb-0 text-muted fs-small">UFU</h6>
                                                            <small class="text-light fs-verysmall"><b>Group</b> with <b>250</b> users</small>
                                                        </ejs-tooltip>
                                                    </div>

                                                    <div class="push-notification-column d-flex align-items-center justify-content-center border-start px-2">
                                                        <button class="e-btn e-link text-light p-0" (click)="onFakeClick()">
                                                            <span class="mts-icon">stream_apps</span>
                                                        </button>  
                                                    </div>

                                                    <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                                        <app-form-checkbox [fCN]="'rm_1'" [fG]="loginForm" [cssClass]="'pb-1'"></app-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card selected-user-group mb-2">
                                                <div class="d-flex align-items-center">
                                                    <div class="remove-icon-column d-flex align-items-center justify-content-center border-end">
                                                        <button class="e-btn e-link text-muted p-0" (click)="onOpenConfirmDelete($event)">
                                                            <span class="mts-icon">delete</span>
                                                        </button>
                                                    </div>

                                                    <div class="d-flex align-items-center px-1">
                                                        <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                                            <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                                        </div>
                                                    </div>

                                                    <div class="flex-grow-1 lh-1">
                                                        <ejs-tooltip content='Tooltip content2'>
                                                            <h6 class="mb-0 text-muted fs-small">Carsten Jobman</h6>
                                                            <small class="text-light fs-verysmall">User</small>
                                                        </ejs-tooltip>
                                                    </div>

                                                    <div class="push-notification-column d-flex align-items-center justify-content-center border-start px-2">
                                                        <ejs-tooltip content='Toggle notifications'>
                                                            <button class="e-btn e-link text-primary p-0" (click)="onFakeClick()">
                                                                <span class="mts-icon">stream_apps</span>
                                                            </button>
                                                        </ejs-tooltip>
                                                    </div>

                                                    <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                                        <app-form-checkbox [fCN]="'rm_1'" [fG]="loginForm" [cssClass]="'pb-1'"></app-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card selected-user-group mb-2">
                                                <div class="d-flex align-items-center">
                                                    <div class="remove-icon-column d-flex align-items-center justify-content-center border-end">
                                                        <button class="e-btn e-link text-muted p-0" (click)="onOpenConfirmDelete($event)">
                                                            <span class="mts-icon">delete</span>
                                                        </button>
                                                    </div>

                                                    <div class="d-flex align-items-center px-1">
                                                        <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                                            <span class="mts-icon fs-5">person</span>
                                                        </div>
                                                    </div>

                                                    <div class="flex-grow-1 lh-1">
                                                        <h6 class="mb-0 text-muted fs-small">steven.seagal-bullshido.com</h6>
                                                        <small class="text-light fs-verysmall">External E-Mail</small>
                                                    </div>

                                                    <div class="push-notification-column d-flex align-items-center justify-content-center border-start px-2">
                                                        <button class="e-btn e-link text-light p-0" (click)="onFakeClick()">
                                                            <span class="mts-icon">stream_apps</span>
                                                        </button>  
                                                    </div>

                                                    <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                                        <app-form-checkbox [fCN]="'rm_1'" [fG]="loginForm" [cssClass]="'pb-1'"></app-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </form>
                        </div>

                        <!-- Prev/Next Buttons (Hidden on Mobile) -->
                        <div class="navigation-buttons d-flex justify-content-between mt-3 d-none d-md-flex">
                            <button *ngIf="currentStep > 0" class="e-btn e-outline w-10" (click)="prevStep()">Prev</button>
                            <button *ngIf="currentStep < steps.length - 1" class="e-btn e-secondary ms-auto w-10" (click)="nextStep()">Next</button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <div class="mt-auto d-md-none col-12 d-flex justify-content-between navigation-buttons-mobile p-2">
                    <button *ngIf="currentStep > 0" class="e-btn e-outline w-10 mx-1" (click)="prevStep()">Prev</button>
                    <!-- Dummy button to keep the space for the flex container -->
                    <button *ngIf="currentStep === 0" class="e-btn e-outline invisible w-10">Prev</button>

                    <button class="btn btn-primary mx-auto w-100" (click)="save()">Save</button>

                    <button *ngIf="currentStep < steps.length - 1" class="e-btn e-secondary w-10 mx-1" (click)="nextStep()">Next</button>
                    <!-- Dummy button to keep the space for the flex container -->
                    <button *ngIf="currentStep === steps.length - 1" class="e-btn e-secondary invisible w-10">Next</button>
                </div>
            </ng-template>
        </ejs-dialog>


        <!-- Dialog for Showing Event -->
        <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showEventDialog [position]='position' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <h5 class="m-0 text-primary">All Events</h5>
                    <ejs-tooltip #helpTooltip [content]='tooltipContent' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column h-100 p-3">
                    <h6 class="text-center text-secondary">Currently, there are no events available!<br> Please check back later or create a new event to get started!</h6>
                    <app-form-input type="search" [placeholder]="('SEARCHFOREVENT' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'search_event_string'" [fG]="loginForm"></app-form-input>
                    <h6 class="text-center text-secondary">No events found for your search.</h6>
                    <!-- SCrollable content-->
                    <div class="custom-scrollbar overflow-y-auto h-100">
                        <div class="card eventContainer danger d-flex flex-row align-items-start w-border mb-2">
                            <div class="d-flex flex-grow-1 border-end">
                                <!-- Left colored border -->
                                <div class="event-bar evb-styled"></div>

                                <!-- Main content area -->
                                <div class="ms-2 py-2 pe-2">
                                    <h6 class="fw-bold mb-1 event-title">Urgent meeting</h6>
                                    <p class="text-muted mb-2 fs-small">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>

                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center me-3">
                                            <span class="mts-icon">schedule</span>
                                            <span class="fs-verysmall ps-1 lh-1">10:00 - 11:30</span>
                                        </div>
                                        <div class="d-flex align-items-center me-3">
                                            <button class="btn btn-link text-muted d-flex align-items-center p-0" (click)="onOpenDialog($event, 'show-event-participants')">
                                                <span class="mts-icon">person</span>
                                                <span class="fs-verysmall ps-1 text-primary">2 Persons</span>
                                            </button>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <span class="mts-icon">update</span>
                                            <span class="fs-verysmall ps-1 lh-1">10 times</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Options menu-->
                            <div class="p-2 align-self-center">
                                <div class="dropdown">
                                    <button class="btn btn-link p-0 text-muted" id="show-event-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="mts-icon">more_horiz</span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="show-event-menu">
                                        <li><a class="dropdown-item" href="#">Edit Event</a></li>
                                        <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Delete Event</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card eventContainer warning d-flex flex-row align-items-start w-border mb-2">
                            <div class="d-flex flex-grow-1 border-end">
                                <!-- Left colored border -->
                                <div class="event-bar evb-styled"></div>

                                <!-- Main content area -->
                                <div class="ms-2 py-2 pe-2">
                                    <h6 class="fw-bold mb-1 event-title">Urgent meeting</h6>
                                    <p class="text-muted mb-2 fs-small">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>

                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center me-3">
                                            <span class="mts-icon">schedule</span>
                                            <span class="fs-verysmall ps-1 lh-1">10:00 - 11:30</span>
                                        </div>
                                        <div class="d-flex align-items-center me-3">
                                            <button class="btn btn-link text-muted d-flex align-items-center p-0" (click)="onOpenDialog($event, 'show-event-participants')">
                                                <span class="mts-icon">person</span>
                                                <span class="fs-verysmall ps-1 text-primary">2 Persons</span>
                                            </button>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <span class="mts-icon">update</span>
                                            <span class="fs-verysmall ps-1 lh-1">10 times</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Options menu-->
                            <div class="p-2 align-self-center">
                                <div class="dropdown">
                                    <button class="btn btn-link p-0 text-muted" id="show-event-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="mts-icon">more_horiz</span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="show-event-menu">
                                        <li><a class="dropdown-item" href="#">Edit Event</a></li>
                                        <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Delete Event</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div class="card eventContainer success d-flex flex-row align-items-start w-border mb-2">
                            <div class="d-flex flex-grow-1 border-end">
                                <!-- Left colored border -->
                                <div class="event-bar evb-styled"></div>

                                <!-- Main content area -->
                                <div class="ms-2 py-2 pe-2">
                                    <h6 class="fw-bold mb-1 event-title">Urgent meeting</h6>
                                    <p class="text-muted mb-2 fs-small">
                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
                                    </p>

                                    <div class="d-flex align-items-center">
                                        <div class="d-flex align-items-center me-3">
                                            <span class="mts-icon">schedule</span>
                                            <span class="fs-verysmall ps-1 lh-1">10:00 - 11:30</span>
                                        </div>
                                        <div class="d-flex align-items-center me-3">
                                            <button class="btn btn-link text-muted d-flex align-items-center p-0" (click)="onOpenDialog($event, 'show-event-participants')">
                                                <span class="mts-icon">person</span>
                                                <span class="fs-verysmall ps-1 text-primary">2 Persons</span>
                                            </button>
                                        </div>
                                        <div class="d-flex align-items-center">
                                            <span class="mts-icon">update</span>
                                            <span class="fs-verysmall ps-1 lh-1">10 times</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- Options menu-->
                            <div class="p-2 align-self-center">
                                <div class="dropdown">
                                    <button class="btn btn-link p-0 text-muted" id="show-event-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span class="mts-icon">more_horiz</span>
                                    </button>
                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="show-event-menu">
                                        <li><a class="dropdown-item" href="#">Edit Event</a></li>
                                        <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Delete Event</a></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ejs-dialog>

        <!-- Dialog for Event Participants -->
        <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showEventParticipantsDialog [position]='position' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <h5 class="m-0 text-primary">Event Participants</h5>
                    <ejs-tooltip #helpTooltip [content]='tooltipContent' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column h-100 p-3">
                    <app-form-input [placeholder]="('SEARCHFORUSER' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'search_user_string'" [fG]="loginForm"></app-form-input>
                    <h6 class="text-center text-secondary">Participant could not be found!</h6>
                    <!-- SCrollable content-->
                    <div class="custom-scrollbar overflow-y-auto">
                        <div class="card selected-user-group mb-2">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center px-1">
                                    <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                    </div>
                                </div>

                                <div class="flex-grow-1 lh-1">
                                    <h6 class="mb-0 text-muted fs-small">Stefan Dragosici</h6>
                                    <small class="text-light fs-verysmall">UFU</small>
                                </div>
                            </div>
                        </div>
                        <div class="card selected-user-group mb-2">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center px-1">
                                    <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                    </div>
                                </div>

                                <div class="flex-grow-1 lh-1">
                                    <h6 class="mb-0 text-muted fs-small">Carsten Jobman</h6>
                                    <small class="text-light fs-verysmall">ADMIN</small>
                                </div>
                            </div>
                        </div>
                        <div class="card selected-user-group mb-2">
                            <div class="d-flex align-items-center">
                                <div class="d-flex align-items-center px-1">
                                    <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                        <span class="mts-icon fs-5">person</span>
                                    </div>
                                </div>
                                <div class="flex-grow-1 lh-1">
                                    <h6 class="mb-0 text-muted fs-small">steven.seagal-bullshido.com</h6>
                                    <small class="text-light fs-verysmall">External E-Mail</small>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </ejs-dialog>

        <!-- Dialog for Dashboard Icon Editing -->
        <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showDashboardIconEditDialog [position]='position' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <h5 class="m-0 text-primary">Edit Dashboard Icons</h5>
                    <ejs-tooltip #helpTooltip [content]='tooltipContentForDashBoardIcons' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column h-100 p-3">
                    <div class="dashboardReorderListBox custom-scrollbar overflow-y-auto">
                        <!-- Wall module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-darkblue icon-size">calendar_view_day</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Wall</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Feedback module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-lightpurple icon-size">cycle</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Feedback</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Workflow module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-pink icon-size">rebase</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Workflow</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Knowlidge Base module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-orange icon-size">psychology</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Knowlidge Base</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Chat module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-aqua icon-size">chat</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Chat</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Community module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-yellow icon-size">forum</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Community</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Meetings module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-blue icon-size">videocam</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Meetings</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Contact module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-darkorange icon-size">import_contacts</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Contact</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- TV module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-cyan icon-size">tv</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">TV</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Profile module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-purple icon-size">perm_contact_calendar</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Profile</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- User module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-darkgreen icon-size">person</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Users</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Groups module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-skyblue icon-size">group</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Groups</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Settings module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-red icon-size">settings</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Settings</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Document Manager module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-brightgreen icon-size">folder</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Document Manager</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                        <!-- Calendar module-->
                        <div class="card mb-2">
                            <div class="d-flex align-items-stretch">
                                <div class="d-flex align-items-center justify-content-center border-end">
                                    <span class="mts-icon text-asphalt icon-size">calendar_month</span>
                                </div>

                                <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                    <h6 class="mb-0 text-muted fs-small">Calendar</h6>
                                </div>

                                <div class="d-flex align-items-center justify-content-center border-start px-2">
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_up</span>
                                    </button>
                                    <button class="btn btn-link p-0 text-muted">
                                        <span class="mts-icon">keyboard_arrow_down</span>
                                    </button>
                                </div>

                                <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                    <app-form-checkbox [fCN]="'rm_1'" [checked]="true" [fG]="loginForm" [cssClass]="'pb-1 m-0'"></app-form-checkbox>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <div class="d-flex align-items-center justify-content-center p-2">
                    <button class="btn btn-primary mx-auto w-50" (click)="save()">Save</button>
                </div>
            </ng-template>
        </ejs-dialog>

        <!-- Dialog for listing All Links -->
        <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showAllLinksDialog [position]='position' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <h5 class="m-0 text-primary">All Links</h5>
                    <ejs-tooltip #helpTooltip [content]='tooltipContentForAllLinks' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column h-100 p-3">
                    <h6 class="text-center text-secondary">Currently, there are no links available!<br> Please check back later or create a new link to get started!</h6>
                    <app-form-input [placeholder]="('SEARCHFORLINK' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'search_link_string'" [fG]="loginForm"></app-form-input>
                    <h6 class="text-center text-secondary">No link found for your search.</h6>
                    <div class="custom-scrollbar overflow-y-auto">
                      <div class="card d-flex flex-row align-items-start w-border mb-2">
                        <div class="d-flex flex-grow-1 border-end">
                          <!-- Main content area -->
                          <div class="ms-2 py-2 pe-2">
                            <h6 class="mb-1 text-muted">Convid langer text damit</h6>
                            <div class="d-flex align-items-center flex-wrap">
                              <div class="d-flex align-items-center me-2">
                                <span class="mts-icon">link</span>
                                <span class="fs-verysmall ps-1">Internal</span>
                              </div>
                              <div class="d-flex align-items-center me-2">
                                <button class="btn btn-link text-muted d-flex align-items-center p-0" (click)="onOpenDialog($event, 'show-event-participants')">
                                  <span class="mts-icon">person</span>
                                  <span class="fs-verysmall ps-1 text-primary">2 Persons</span>
                                </button>
                              </div>
                              <div class="d-flex align-items-center me-2">
                                <span class="mts-icon">update</span>
                                <span class="fs-verysmall ps-1">12.11.2024,15:39</span>
                              </div>
                              <div class="d-flex align-items-center">
                                <span class="mts-icon">devices</span>
                                <span class="fs-verysmall ps-1">Mobiles</span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <!-- Options menu-->
                        <div class="p-2 align-self-center">
                          <div class="dropdown">
                            <button class="btn btn-link p-0 text-muted" id="show-event-menu" data-bs-toggle="dropdown" aria-expanded="false">
                              <span class="mts-icon">more_horiz</span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="show-event-menu">
                              <li><a class="dropdown-item" href="#">Edit Link</a></li>
                              <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Delete Link</a></li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                </div>
            </ng-template>
        </ejs-dialog>

        <!-- Dialog for Adding/Editing a link -->
        <ejs-dialog target=".dialogs" class="steps-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #newLinkDialog header="" [position]='position' [isModal]="true" [allowDragging]="false" [width]="'70%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="false">
            <ng-template #header class="p-1">
                <div class="steps-list d-flex justify-content-between align-items-center w-100">
                    <!-- Numbers of steps before the current step -->
                    <div class="d-flex justify-content-start flex-shrink-0">
                        <div *ngFor="let step of stepsLink; let i = index" [ngClass]="{
                            'active-step': i === currentStep,
                            'completed-step': i < currentStep,
                            'inactive-step': i > currentStep
                        }"
                        (click)="goToStep(i)">
                            <span *ngIf="i < currentStep" class="step-number d-flex justify-content-center align-items-center fs-small">
                              {{ i + 1 }}
                            </span>
                        </div>
                    </div>

                    <!-- Current step and title -->
                    <div class="d-flex flex-grow-1 active-step align-items-center">
                      <span class="step-number me-2 d-flex justify-content-center align-items-center fs-small">
                        {{ currentStep + 1 }}
                      </span>
                      <span class="step-title fs-small">{{ stepsLink[currentStep].title }}</span>
                    </div>

                    <!-- Remaining steps numbers -->
                    <div class="d-flex justify-content-end flex-shrink-0">
                        <div *ngFor="let step of stepsLink; let i = index" [ngClass]="{
                            'active-step': i === currentStep,
                            'completed-step': i < currentStep,
                            'inactive-step': i > currentStep
                        }"
                        (click)="goToStep(i)">
                            <span *ngIf="i > currentStep" class="step-number d-flex justify-content-center align-items-center fs-small">
                              {{ i + 1 }}
                            </span>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex h-100">
                    <!-- Left Column -->
                    <div class="col-md-4 col-12 d-none d-md-flex flex-column left-container h-100 p-3">
                        <div class="text-center mb-3">
                            <h5>Add Link</h5>
                        </div>

                        <!-- Scrollable Steps List -->
                        <div class="custom-scrollbar">
                            <ul class="steps-list list-unstyled">
                                <li *ngFor="let step of stepsLink; let i = index"
                                    [ngClass]="{
                                        'active-step': i === currentStep,
                                        'completed-step': i < currentStep,
                                        'inactive-step': i > currentStep
                                    }"
                                    (click)="goToStep(i)">
                                <span class="step-number">{{ i + 1 }}</span>
                                <span class="step-title">{{ step.title }}</span>
                                </li>
                            </ul>
                        </div>

                        <!-- Save Button (Hidden on Mobile) -->
                        <div class="save-button-container text-center mt-3 d-none d-md-block">
                        <button class="e-btn e-primary w-100" (click)="save()">Save</button>
                        </div>
                    </div>

                    <!-- Right Column -->
                    <div class="col-md-8 col-12 d-flex flex-column flex-grow-1 right-container h-100 overflow-y-auto p-3">
                        <div class="d-flex justify-content-between align-items-center mb-3">
                            <div class="d-flex">
                                <h5 class="m-0">{{ stepsLink[currentStep].title }}</h5>
                                <ejs-tooltip #helpTooltip [content]='tooltipContent' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                                    <span class="mts-icon cursor-pointer">help</span>
                                </ejs-tooltip>
                            </div>
                            <!--!!!!!!!!!!!!!THIS CLOSE BUTTON MUST BE WITH THESE CLASSES, BECAUSE ONLY BOOTSTRAP HAS SUCH BUTTON CLASS!!!!!!!!!!!!!-->
                            <button type="button" class="btn btn-close" aria-label="Close" (click)="closeDialog('new-link')"></button>
                        </div>

                        <!-- Scrollable Form Container -->
                        <div class="custom-scrollbar overflow-y-auto">
                            <form class="p-1 d-flex flex-column h-100">
                                <ng-container [ngSwitch]="currentStep">
                                    <ng-container *ngSwitchCase="0">
                                        <!-- Link Name-->
                                        <div class="d-flex align-items-center">
                                            <label class="pb-1 text-secondary fw-bold">Link Name</label>
                                            <span class="ms-1 text-secondary fs-verysmall">{{ linkNameValue.length }}/35 characters left</span>
                                        </div>
                                        <div class="mb-3">
                                            <ejs-textbox name="linkNameValue" [value]="linkNameValue" placeholder="Type Link Name here" aria-placeholder="Link Name" aria-labelledby="Link Name" (input)="onInputChange($event)" maxlength="35"></ejs-textbox>
                                        </div>
                                        <!-- Link URL-->
                                        <div class="d-flex align-items-center">
                                            <label class="text-secondary fw-bold">Link URL</label>                                            
                                        </div>
                                        <div class="mb-3">
                                            <ejs-textbox name="linkURL" [value]="linkURL" placeholder="Paste Link URL here" aria-placeholder="Link URL" aria-labelledby="Link URL" ></ejs-textbox>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngSwitchCase="1">
                                        <!-- Link Type -->
                                        <div class="d-flex align-items-center">
                                            <label class="text-secondary fw-bold">Link Type</label>                                            
                                        </div>
                                        <div class="d-flex align-items-center mb-4">
                                            <div class="col-4 col-md-3 me-2">
                                                <ejs-radiobutton label="Internal Link" name="link-type" checked="true"></ejs-radiobutton>
                                            </div> 
                                            <div class="col-4 col-md-3 me-2">
                                                <ejs-radiobutton label="External Link" name="link-type"></ejs-radiobutton>
                                            </div>
                                        </div>
                                        <!-- Show Link on Platforms -->
                                        <div class="d-flex align-items-center">
                                            <label class="text-secondary fw-bold">Show Link on Platforms</label>                                            
                                        </div>
                                        <div class="d-flex align-items-center mb-3">
                                            <div class="col-4 col-md-3 me-2">
                                                <div class="d-flex align-items-center">
                                                    <app-form-checkbox [label]="'Mobiles'" [fCN]="'mobiles'" [fG]="loginForm" [cssClass]="'pb-1'"></app-form-checkbox>
                                                </div>  
                                            </div> 
                                            <div class="col-4 col-md-3 me-2">
                                                <div class="d-flex align-items-center">
                                                    <app-form-checkbox [label]="'Desktops'" [fCN]="'desktops'" [fG]="loginForm" [cssClass]="'pb-1'"></app-form-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                        
                                        <!-- Link availablity -->
                                        <div class="d-flex align-items-center">
                                            <label class="text-secondary fw-bold mb-1">Link availablility</label>                                            
                                        </div>
                                        <div class="row g-2 justify-content-start">
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center">
                                                        <label class="text-muted fs-default">Show from</label>
                                                    </div>
                                                    <div class="col-9 col-sm-9 col-md-6 col-lg-6">
                                                        <ejs-datetimepicker class="w-100" id="startDateTime" [locale]="'de-DE'" format="dd.MM.yyyy HH:mm" step="15" [timeFormat]="'HH:mm'"></ejs-datetimepicker>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="row">
                                                    <div class="col-3 col-sm-3 col-md-3 col-lg-2 d-flex align-items-center">
                                                        <label class="text-muted fs-default">Show until</label>
                                                    </div>
                                                    <div class="col-9 col-sm-9 col-md-6 col-lg-6">
                                                        <ejs-datetimepicker class="w-100" id="endDateTime" [locale]="'de-DE'" format="dd.MM.yyyy HH:mm" step="15" [timeFormat]="'HH:mm'"></ejs-datetimepicker>
                                                    </div>
                                                </div>
                                            </div>
                                        </div> 
                                    </ng-container>
                                    <ng-container *ngSwitchCase="2">
                                        <!-- User/Group and External Email-->
                                        <div class="row g-2 mb-1">
                                            <div class="col-6">
                                                <div class="d-flex-column">
                                                    <label class="text-secondary fw-bold">Add Group/User</label>
                                                    <ejs-dropdownlist
                                                    [dataSource]="usersAndGroups"
                                                    [fields]="userGroupFields"
                                                    [query]="userGroupQuery"
                                                    [allowFiltering]="true"
                                                    (filtering)="onFiltering($event)"
                                                    placeholder="Type group/user name">
                                                    </ejs-dropdownlist>
                                                </div>
                                            </div>

                                            <!-- Selected Participants -->
                                            <div class="d-flex align-items-center">
                                                <label class="text-secondary fw-bold">Selected Users/Groups</label>
                                            </div>
                                            <div class="small-filter-dropdown d-flex justify-content-between align-items-center">
                                                <ejs-dropdownlist popupWidth='auto' [dataSource]="userFilterData" [fields]="userFilterFields" [placeholder]="'Select Filter'" [sortOrder]="'Ascending'" [value]="userFilterSelectedValue" [valueTemplate]='valueTemplate' class="filter-dropdown" (change)="onUserFilterChange($event)" >
                                                    <ng-template #valueTemplate let-data="">
                                                        <div class="d-flex align-items-center text-muted">
                                                            <span class="mts-icon p-1">filter_list</span>
                                                            <span class='value fs-small'>{{data.text}}</span>
                                                        </div>
                                                    </ng-template>
                                                </ejs-dropdownlist>
                                                <div class="dropdown">
                                                    <button class="btn btn-link p-0 text-muted" id="notifications-menu" data-bs-toggle="dropdown" aria-expanded="false">
                                                        <span class="mts-icon">more_vert</span>
                                                    </button>
                                                    <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="notifications-menu">
                                                        <li><a class="dropdown-item" href="#">Select all</a></li>
                                                        <li><a class="dropdown-item" href="#" (click)="onOpenConfirmDelete($event)">Remove selected</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="custom-scrollbar overflow-y-auto">
                                            <div class="card selected-user-group mb-2">
                                                <div class="d-flex align-items-center">
                                                    <div class="remove-icon-column d-flex align-items-center justify-content-center border-end">
                                                        <button class="e-btn e-link text-muted p-0" (click)="onOpenConfirmDelete($event)">
                                                            <span class="mts-icon">delete</span>
                                                        </button>
                                                    </div>

                                                    <div class="d-flex align-items-center px-1">
                                                        <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                                            <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                                        </div>
                                                    </div>

                                                    <div class="flex-grow-1 lh-1">
                                                        <h6 class="mb-0 text-muted fs-small">UFU</h6>
                                                        <small class="text-light fs-verysmall">Group</small>
                                                    </div>

                                                    <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                                        <app-form-checkbox [fCN]="'rm_1'" [fG]="loginForm" [cssClass]="'pb-1'"></app-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="card selected-user-group mb-2">
                                                <div class="d-flex align-items-center">
                                                    <div class="remove-icon-column d-flex align-items-center justify-content-center border-end">
                                                        <button class="e-btn e-link text-muted p-0" (click)="onOpenConfirmDelete($event)">
                                                            <span class="mts-icon">delete</span>
                                                        </button>
                                                    </div>

                                                    <div class="d-flex align-items-center px-1">
                                                        <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                                            <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                                        </div>
                                                    </div>

                                                    <div class="flex-grow-1 lh-1">
                                                        <h6 class="mb-0 text-muted fs-small">Carsten Jobman</h6>
                                                        <small class="text-light fs-verysmall">User</small>
                                                    </div>

                                                    <div class="checkbox-column d-flex align-items-center justify-content-center border-start px-2">
                                                        <app-form-checkbox [fCN]="'rm_1'" [fG]="loginForm" [cssClass]="'pb-1'"></app-form-checkbox>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                            </form>
                        </div>
                        <!-- Prev/Next Buttons (Hidden on Mobile) -->
                        <div class="navigation-buttons d-flex justify-content-between mt-3 d-none d-md-flex">
                            <button *ngIf="currentStep > 0" class="e-btn e-outline w-10" (click)="prevStep()">Prev</button>
                            <button *ngIf="currentStep < steps.length - 1" class="e-btn e-secondary ms-auto w-10" (click)="nextStep()">Next</button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <div class="mt-auto d-md-none col-12 d-flex justify-content-between navigation-buttons-mobile p-2">
                    <button *ngIf="currentStep > 0" class="e-btn e-outline w-10 mx-1" (click)="prevStep()">Prev</button>
                    <!-- Dummy button to keep the space for the flex container -->
                    <button *ngIf="currentStep === 0" class="e-btn e-outline invisible w-10">Prev</button>

                    <button class="btn btn-primary mx-auto w-100" (click)="save()">Save</button>

                    <button *ngIf="currentStep < stepsLink.length - 1" class="e-btn e-secondary w-10 mx-1" (click)="nextStep()">Next</button>
                    <!-- Dummy button to keep the space for the flex container -->
                    <button *ngIf="currentStep === stepsLink.length - 1" class="e-btn e-secondary invisible w-10">Next</button>
                </div>
            </ng-template>
        </ejs-dialog>
        
        <!-- Dialog for Language selection -->
        <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showLanguageDialog [position]='position' [isModal]="true" [allowDragging]="false" [width]="'30%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <h5 class="m-0 text-primary">Select language</h5>
                    <ejs-tooltip #helpTooltip [content]='tooltipContentForDashBoardIcons' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column h-100 p-3">
                    <div class="custom-scrollbar overflow-y-auto">
                        <div class="d-flex p-1 pb-3">
                            <input #languageSearchTextbox id="languageSearchTextbox" class="e-input" type="text" placeholder="Filter language" title="Type in a country" (keyup)=onkeyup($event) />
                        </div>
                        <ejs-listview #list id='list' [dataSource]='languages'[fields]='langiageFields' headerTitle='Contacts' cssClass='e-list-template' [showHeader]='false' sortOrder='Ascending'>
                            <ng-template #template let-data="">
                                <div class="e-list-wrapper p-0">

                                        <div class="d-flex align-items-stretch">
                                            <div class="d-flex align-items-center justify-content-center p-2">
                                                <div class="e-avatar e-avatar-circle e-avatar-xsmall">
                                                    <img [src]="data.flag" alt="profile_pic">
                                                </div>
                                            </div>
            
                                            <div class="flex-grow-1 d-flex ps-2 align-items-center">
                                                <h6 class="mb-0 text-muted fs-small">{{data.text}}</h6>
                                            </div>
            
                                            <div class="checkbox-column d-flex align-items-center justify-content-center px-2">
                                                <span class="mts-icon fs-5" *ngIf="data.selected">check</span>
                                            </div>
                                        </div>
                                </div>
                            </ng-template>
                        </ejs-listview>                        
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <div class="d-flex align-items-center justify-content-center p-2">
                    <button class="btn btn-primary mx-auto w-50" (click)="save()">Save</button>
                </div>
            </ng-template>
        </ejs-dialog>
        
        <!-- Dialog for Push Settings -->
        <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showPushSettingseDialog [position]='position' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <h5 class="m-0 text-primary">Notification Settings</h5>
                    <ejs-tooltip #helpTooltip [content]='tooltipContentForDashBoardIcons' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column h-100 p-3">
                    <div class="custom-scrollbar overflow-y-auto">
                            <!-- Global Settings Section -->
                            <div class="row mb-3">
                                <div class="col-12">
                                    <label class="text-secondary fw-bold">Global Settings</label>
                                </div>
                                <div class="row align-items-center mb-2">
                                    <div class="col-12 d-flex align-items-center">
                                        <label class="me-auto pe-2 text-muted">Disable all: push, e-mail and web notifications</label>
                                        <div class="flex-shrink-0">
                                            <ejs-switch onLabel="AUF" offLabel="AUS" [(checked)]="switchState"></ejs-switch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        
                            <!-- Custom Settings Section -->
                            <div class="row align-items-center">
                                <div class="col-12">
                                    <label class="text-secondary fw-bold">Custom Settings</label>
                                </div>
                                <div class="row align-items-center mb-2">
                                    <div class="col-12 col-md-6 d-flex align-items-center">
                                        <label class="me-auto pe-2 text-muted">Disable Push Notifications on mobile devices</label>
                                        <div class="flex-shrink-0">
                                            <ejs-switch onLabel="AUF" offLabel="AUS" [(checked)]="switchState"></ejs-switch>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 d-flex align-items-center">
                                        <label class="me-auto pe-2 text-muted">Disable E-mail Notifications</label>
                                        <div class="flex-shrink-0">
                                            <ejs-switch onLabel="AUF" offLabel="AUS" [(checked)]="switchState"></ejs-switch>
                                        </div>
                                    </div>
                                </div>
                        
                                <!-- Setting 2 -->
                                <div class="row align-items-center mb-2">
                                    <div class="col-12 col-md-6 d-flex align-items-center">
                                        <label class="me-auto pe-2 text-muted">Disable Website Notifications</label>
                                        <div class="flex-shrink-0">
                                            <ejs-switch onLabel="AUF" offLabel="AUS" [(checked)]="switchState"></ejs-switch>
                                        </div>
                                    </div>
                                    <div class="col-12 col-md-6 d-flex align-items-center">
                                        <label class="me-auto pe-2 text-muted">Disable Website Chat Notifications</label>
                                        <div class="flex-shrink-0">
                                            <ejs-switch onLabel="AUF" offLabel="AUS" [(checked)]="switchState"></ejs-switch>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>                    
                    </div>

            </ng-template>
            <ng-template #footerTemplate>
                <div class="d-flex align-items-center justify-content-center p-2">
                    <button class="btn btn-primary mx-auto w-50" (click)="save()">Save</button>
                </div>
            </ng-template>
        </ejs-dialog> 
    </div>

</ng-container>
