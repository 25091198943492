<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="('DASHBOARDLINKSALL' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <div class="ms-2">
        <app-tooltip [icon]="'help'" [text]="('DASHBOARDLINKSTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
      </div>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="d-flex flex-column h-100 p-3">

      <app-headline [title]="('DASHBOARDLINKSAVAILABLE' | translate)" [type]="'h6'" [class]="'text-center text-secondary'"></app-headline>
      <app-form-input type="search" [placeholder]="('SEARCHFORLINK' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'searchString'" [fG]="dashboardLinksForm"></app-form-input>
      <app-headline [title]="('DASHBOARDNOLINKSFOUND' | translate)" [type]="'h6'" [class]="'text-center text-secondary'"></app-headline>

      <!-- Scrollable content-->
      <div class="custom-scrollbar overflow-y-auto">
        <ng-container *ngFor="let dashboardLink of dashboardLinks">

          <div class="card d-flex flex-row align-items-start w-border mb-2">
            <div class="d-flex flex-grow-1 border-end">
              <div class="ms-2 py-2 pe-2">
                <app-headline [title]="dashboardLink.name" [type]="'h6'" [class]="'mb-1 text-muted'"></app-headline>
                <div class="d-flex align-items-center flex-wrap">
                  <app-form-button [style]="'btn'" [text]="('DASHBOARDTYPE' + dashboardLink.type.toUpperCase()) | translate" [cssClass]="'btn-flat text-muted fs-verysmall no-cursor-pointer p-0'" [icon]="'link'" [iconPosition]="'left'"></app-form-button>
                  <app-form-button *ngIf="dashboardLink.isEditable" (click)="edit(dashboardLink)" [style]="'btn'" [text]="dashboardLink.participantsUserIDs.length + ' ' + ('PERSONS' | translate)" [cssClass]="'btn-link text-primary fs-verysmall px-1 py-0'" [iconClass]="'text-primary'" [icon]="'person'" [iconPosition]="'left'"></app-form-button>
                  <app-form-button *ngIf="!dashboardLink.isEditable" [style]="'btn'" [text]="dashboardLink.participantsUserIDs.length + ' ' + ('PERSONS' | translate)" [cssClass]="'btn-link text-muted fs-verysmall no-cursor-pointer px-1 py-0'" [iconClass]="'text-muted'" [icon]="'person'" [iconPosition]="'left'"></app-form-button>
                  <app-form-button [style]="'btn'" [text]="(dashboardLink.dateTimeStart | date : 'short') ?? ''" [cssClass]="'btn-flat text-muted fs-verysmall no-cursor-pointer p-0'" [icon]="'update'" [iconPosition]="'left'"></app-form-button>
                  <app-form-button [style]="'btn'" [text]="('DASHBOARDPLATFORM' + dashboardLink.platform.toUpperCase()) | translate" [cssClass]="'btn-flat text-muted fs-verysmall no-cursor-pointer p-0'" [icon]="'devices'" [iconPosition]="'left'"></app-form-button>
                </div>
              </div>
            </div>

            <div *ngIf="dashboardLink.isEditable" class="p-2 align-self-center">
              <div class="dropdown">
                <button type="button" class="btn btn-link p-0 text-muted" data-bs-toggle="dropdown" aria-expanded="false">
                  <app-icon-badge [style]="'notification'" [icon]="'more_horiz'" [screenReaderText]="'SRMOREOPTIONS' | translate"></app-icon-badge>
                </button>
                <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="eventManagement">
                  <li><app-link (click)="edit(dashboardLink)" [title]="('DASHBOARDEDITLINK' | translate)" [style]="'dropdown'">{{ ('DASHBOARDEDITLINK' | translate) }}</app-link></li>
                  <li><app-link (click)="showDeleteOverlay(dashboardLink)" [title]="('DASHBOARDDELETELINK' | translate)" [style]="'dropdown'">{{ ('DASHBOARDDELETELINK' | translate) }}</app-link></li>
                </ul>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </ng-template>
</ejs-dialog>

<app-dialog-dashboard-link *ngIf="dashboardLinkToEdit" [(visible)]="dashboardLinkDialog" [dashboardLink]="dashboardLinkToEdit"></app-dialog-dashboard-link>
