import {HttpInterceptorFn} from '@angular/common/http';
import {catchError} from "rxjs";
import {inject} from "@angular/core";
import {ToasterService} from "../services/toaster.service";

export const statusInterceptor: HttpInterceptorFn = (req, next) => {
  const toasterService = inject(ToasterService);
  return next(req).pipe(
    catchError(error => {
      if (error.status === 555) {
        toasterService.error(error.error.title, error.error.message);
        // if you want to return a custom error
        /*return of(new HttpResponse({
          status: 200,
          body: {
            message: 'Fehler 555 wurde abgefangen',
            data: null
          }
        }));*/
      }
      throw error;
    })
  );
};
