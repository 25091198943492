import {Component, EventEmitter, Input, Output} from '@angular/core';
import {Dialog, DialogModule, TooltipModule} from "@syncfusion/ej2-angular-popups";
import {FormInputComponent} from "../../../shared/elements/form-input/form-input.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TYPE} from "../../../shared/types/types";
import {DialogService} from "../../../shared/services/dialog.service";
import {LanguageService} from "../../../shared/services/language.service";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {DatePipe, NgForOf, NgIf} from "@angular/common";
import {DialogDashboardLinkComponent} from "../dialog-dashboard-link/dialog-dashboard-link.component";
import {IconBadgeComponent} from "../../../shared/elements/icon-badge/icon-badge.component";
import {LinkComponent} from "../../../shared/elements/link/link.component";
import {DialogUtility} from "@syncfusion/ej2-popups";
import {ContentComponent} from "../../../shared/elements/content/content.component";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";

@Component({
  selector: 'app-dialog-dashboard-link-manager',
  standalone: true,
  imports: [
    DialogModule,
    FormInputComponent,
    TooltipModule,
    TranslatePipe,
    HeadlineComponent,
    TooltipComponent,
    NgIf,
    DialogDashboardLinkComponent,
    NgForOf,
    IconBadgeComponent,
    LinkComponent,
    ContentComponent,
    DatePipe,
    FormButtonComponent
  ],
  templateUrl: './dialog-dashboard-link-manager.component.html',
  styleUrl: './dialog-dashboard-link-manager.component.scss'
})
export class DialogDashboardLinkManagerComponent {
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public dashboardLinksForm: FormGroup = this.formBuilder.group({
    searchString: [''],
  });
  public dashboardLinks: TYPE.DashboardLink[] = [
    {
      name: 'Humanstars',
      url: 'https://humanstars.app',
      type: 'external',
      platform: 'both',
      dateTimeStart: new Date(),
      dateTimeEnd: new Date(),
      participantsUserIDs: [108],
      participantsGroupIDs: [8, 6],
      isEditable: true
    }
  ];
  public dashboardLinkToEdit: TYPE.DashboardLink | null = null;
  public dashboardLinkDialog: boolean = false;
  private dialogInstance: Dialog | null = null;

  constructor(
    public dialogService: DialogService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder
  ) {
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

  public edit(dashboardLink: TYPE.DashboardLink): void {
    this.dashboardLinkToEdit = dashboardLink;
    this.dashboardLinkDialog = true;
  }

  public showDeleteOverlay(dashboardLink: TYPE.DashboardLink): void {
    this.dialogInstance = DialogUtility.confirm({
      title: '<h6 class="text-danger fw-bold m-0 pt-1">' + this.languageService.getTranslationByKey('CONFIRMITEMDELETE') + '</h6>',
      content: this.languageService.getTranslationByKey('AREYOUSUREYOUWANTTODELETE'),
      cancelButton: {
        text: this.languageService.getTranslationByKey('CANCEL'),
        click: () => this.showDeleteOverlayCancel()
      },
      okButton: {
        text: this.languageService.getTranslationByKey('DELETE'),
        click: () => this.showDeleteOverlayConfirmed(),
        cssClass: 'bg-danger brd-danger'
      },
      showCloseIcon: true,
      closeOnEscape: true,
      position: {X: 'center', Y: 'center'},
      animationSettings: {effect: 'Fade'},
      zIndex: 9999
    });
  }

  private showDeleteOverlayConfirmed(): void {
    // TODO DELETE EVENT
    this.dialogInstance?.hide();
  }

  private showDeleteOverlayCancel(): void {
    this.dialogInstance?.hide();
  }
}
