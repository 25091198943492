<ng-container *ngIf="!(loadingService.loadingIndicator$ | async)">
    <!-- ALREADY IMPLEMENTED AS MODULE-->
    <div class="icon-list-container position-relative">
        <div class="icon-list position-fixed top-0 end-0 p-1">
            <!-- Notification Bell -->
            <button type="button" class="btn btn-link p-1 text-muted position-relative">
                <span class="mts-icon">notifications</span>
                <span class="badge-dot position-absolute top-0 translate-middle p-1 bg-primary border border-light rounded-circle">
                    <span class="visually-hidden">New alerts</span>
                </span>
            </button>

            <!-- Message Icon -->
            <button type="button" class="btn btn-link p-1 text-muted position-relative">
                <span class="mts-icon">chat_bubble</span>
                <span class="badge-dot position-absolute top-0 translate-middle p-1 bg-primary border border-light rounded-circle">
                    <span class="visually-hidden">New messages</span>
                </span>
            </button>

            <!-- Avatar Dropdown -->
            <div class="dropdown d-inline">
                <button type="button" class="btn btn-link p-1 text-muted dropdown-toggle" data-bs-toggle="dropdown" aria-expanded="false" data-bs-display="static">
                    <div class="e-avatar e-avatar-circle e-avatar-xsmall">
                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic">
                    </div>
                </button>
                <ul class="dropdown-menu dropdown-menu-end">
                    <li><a class="dropdown-item" href="#">Language</a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a class="dropdown-item" href="#">Profile</a></li>
                    <li><a class="dropdown-item" href="#">Notifications</a></li>
                    <li><a class="dropdown-item" href="#">Icon Bar OFF</a></li>
                    <li><hr class="dropdown-divider"></li>
                    <li><a class="dropdown-item" href="#">Logout</a></li>
                </ul>
            </div>
        </div>
    </div>

    <!-- BACK TO DASHBOARD ICON -->
    <div class="home-icon-container position-relative">
        <div class="home-icon position-fixed top-0 start-0 p-0">
            <button type="button" class="btn btn-link p-1 text-muted position-relative">
                <span class="mts-icon fs-4">apps</span>
            </button>
        </div>
    </div>

    <!-- STICKY ICON BAR -->
    <div class="icon-bar d-none d-md-flex flex-column align-items-center position-fixed border-top border-end border-bottom shadow p-m">
        <!-- Wall icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-darkblue p-s'" [icon]="'calendar_view_day'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Feedback icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-lightpurple p-s'" [icon]="'cycle'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Workflow icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-pink p-s'" [icon]="'rebase'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Knowlidge Base icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-orange p-s'" [icon]="'psychology'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Chat icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-aqua p-s'" [icon]="'chat'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Community icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-yellow p-s'" [icon]="'forum'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Meetings icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-blue p-s'" [icon]="'videocam'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Contact icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-darkorange p-s'" [icon]="'import_contacts'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- TV icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-cyan p-s'" [icon]="'tv'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Profile icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-purple p-s'" [icon]="'perm_contact_calendar'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- User icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-darkgreen p-s'" [icon]="'person'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Groups icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-skyblue p-s'" [icon]="'group'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Settings icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-red p-s'" [icon]="'settings'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Document Manager icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-brightgreen p-s'" [icon]="'folder'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
        <!-- Calendar icon-->
        <app-form-button [style]="'btn'" [cssClass]="'btn-link text-asphalt p-s'" [icon]="'calendar_month'" [iconPosition]="'left'" [iconClass]="'fs-5'"></app-form-button>
    </div>
    
    <div id="app-test1" class="container d-flex flex-column flex-grow-1 mt-5 pt-4 pb-4 h-100" role="main" [attr.aria-label]="('PROFILE' | translate)">
        <app-headline [type]="'h5'" [class]="'text-muted fw-bold mb-2'" [title]="('PROFILE' | translate)"></app-headline>
        <div class="custom-scrollbar overflow-y-auto mb-2">
            <div class="profileCard card mb-3">
                <div class="card-body">
                    <div class="d-flex justify-content-end mb-3">
                        <div class="my-1 d-none d-md-flex">
                            <app-form-button [text]="'SAVECHANGES' | translate" (click)="onFakeClick()" [icon]="'save'" [iconPosition]="'right'" [cssClass]="'e-primary e-small fs-default fw-semibold'"></app-form-button>
                        </div>
                        <app-form-button [isFab]="true" [fabPosition]="'bottom-right'" [fabTargetContainer]="'#app-test1'" [text]="'SAVECHANGES' | translate" (click)="onFakeClick()" [icon]="'save'" [iconPosition]="'right'" [cssClass]="'d-flex d-md-none e-primary fw-semibold'"></app-form-button>
                        <!--<button ejs-fab iconCss='e-icons e-save' cssClass='d-flex d-md-none fw-semibold' [content]="'SAVECHANGES' | translate" iconPosition='Right' position='BottomRight' target='#app-test1'>
                        </button>-->
                    </div>
                    <div class="row g-3">
                        <!-- Profile Image Section -->
                        <div class="col-md-4 my-3 text-center d-flex flex-column">
                            <div class="mb-3">
                                <app-headline [type]="'h5'" [class]="'text-capitalize text-muted fs-default fw-bold'" [title]="('PROFILEIMAGE' | translate)"></app-headline>
                            </div>
                            <div class="d-flex flex-grow-1 flex-column align-items-center justify-content-center">
                                <img src="https://i.pinimg.com/564x/3f/e0/c6/3fe0c626f3330adb28c6b2d20b973d52.jpg" class="img-thumbnail" alt="profile picture">
                                <div class="d-flex align-items-center my-2">
                                    <app-form-button [text]="'Change Profile Image' | translate" (click)="onOpenDialog($event, 'change-avatar')" [cssClass]="'e-flat fw-bold text-skyblue py-0'" [icon]="'photo_camera'" [iconPosition]="'left'"></app-form-button>
                                </div>
                            </div>
                            <div class="align-items-center">  
                                <app-form-button [text]="'CHANGECREDENTIALS' | translate" (click)="onOpenDialog($event,'change-credentials')" [cssClass]="'e-skyblue e-small mt-1 fs-default fw-semibold '"></app-form-button>
                            </div>
                        </div>

                        <!-- Employee Details Section -->
                        <div class="col-md-8">
                            <div class="mb-3">
                                <app-headline [type]="'h5'" [class]="'text-capitalize text-muted fs-default fw-bold'" [title]="('EMPLOYEEDETAIS' | translate)"></app-headline>
                            </div>
                            <!--<app-form-dropdown [fG]="loginForm" [fCN]="'userGroupSelector'" [dataSource]="displayData" [mapping]="userGroupFields"  [allowFiltering]="true" [placeholder]="'SELECTFILTER' | translate" (reachedEndOfList)="onScroll()" (itemSelected)="onSelectItem($event)" (filteringPerformed)="onFiltering($event)"></app-form-dropdown>

                            <div class="selected-items">
                                <h3>Selected Items:</h3>
                                <ul>
                                  <li *ngFor="let item of selectedItems">{{ item.name }}</li>
                                </ul>
                            </div>-->
                            <form>
                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <app-form-label [label]="'COMPANYNAME' | translate" [maxChars]="60" [currentChars]="loginForm.controls['companyName'].value.length"></app-form-label>
                                        
                                        <app-form-input [placeholder]="('COMPANYNAME' | translate)" [errorText]="('COMPANYNAMEREQUIRED' | translate)" [fCN]="'companyName'" [fG]="loginForm" [maxChars]="60"></app-form-input>
                                    </div>
                                    <div class="col-md-6">
                                        <app-form-label [label]="'SALUTATION' | translate" [maxChars]="60" [currentChars]="loginForm.controls['salutation'].value.length"></app-form-label>
                                        
                                        <app-form-input [placeholder]="('SALUTATION' | translate)" [errorText]="('SALUTATIONREQUIRED' | translate)" [fCN]="'salutation'" [fG]="loginForm" [maxChars]="10"></app-form-input>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <app-form-label [label]="'FIRSTNAME' | translate" [maxChars]="40" [currentChars]="loginForm.controls['firstName'].value.length"></app-form-label>
                                        
                                        <app-form-input [placeholder]="('FIRSTNAME' | translate)" [errorText]="('FIRSTNAMEREQUIRED' | translate)" [fCN]="'firstName'" [fG]="loginForm" [maxChars]="40"></app-form-input>
                                    </div>
                                    <div class="col-md-6">
                                        <app-form-label [label]="'LASTNAME' | translate" [maxChars]="40" [currentChars]="loginForm.controls['lastName'].value.length"></app-form-label>
                                        
                                        <app-form-input [placeholder]="('LASTNAME' | translate)" [errorText]="('LASTNAMEREQUIRED' | translate)" [fCN]="'lastName'" [fG]="loginForm" [maxChars]="40"></app-form-input>
                                    </div>
                                </div>

                                <div class="row mb-2">
                                    <div class="col-md-6">
                                        <app-form-label [label]="'MOBILE' | translate"></app-form-label>
                                        <app-form-input [placeholder]="('MOBILE' | translate)" [errorText]="('MOBILEREQUIRED' | translate)" [iconBack]="'content_copy'" [iconClickType]="'back'" [fCN]="'mobileNumber'" [fG]="loginForm"></app-form-input>
                                    </div>
                                    <div class="col-md-6">
                                        <app-form-label [label]="'EMAIL' | translate"></app-form-label>
                                        <app-form-input [placeholder]="('EMAIL' | translate)" [errorText]="('EMAILREQUIRED' | translate)" [iconBack]="'content_copy'" [iconClickType]="'back'" [fCN]="'emailAddress'" [fG]="loginForm"></app-form-input>
                                    </div>
                                </div>

                                <div class="row">
                                    <div class="col-md-6">
                                        <app-form-label [label]="'TELEPHONE' | translate"></app-form-label>
                                        <app-form-input [placeholder]="('TELEPHONE' | translate)" [errorText]="('TELEPHONEREQUIRED' | translate)" [iconBack]="'content_copy'" [fCN]="'telephone'" [iconClickType]="'back'" [fG]="loginForm"></app-form-input>
                                    </div>
                                    <div class="col-md-6 pt-2 d-flex align-items-center mt-md-0 mt-3">
                                        <app-form-button [text]="'CUSTOMINFORMATION' | translate" (click)="onOpenDialog($event, 'show-custominfo')" [cssClass]="'e-grey e-small fs-default fw-semibold w-100'"></app-form-button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                    <hr>
                    <!-- Global Settings Section -->
                    <div class="mb-2">
                        <app-headline [type]="'h5'" [class]="'text-capitalize text-muted fs-default fw-bold'" [title]="('NOTIFICATIONSETTIGNS' | translate)"></app-headline>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex">
                            <label class="text-secondary fw-bold">Global Settings</label>
                        </div>
                        <div class="d-flex align-items-center">
                            <div class="col-10 col-md-4">
                                <label class="fs-default text-muted">Disable all: push, e-mail and web notifications</label>
                            </div>
                            <div class="col-2 col-md-1 justify-content-end d-flex pe-1">
                                <ejs-switch onLabel="AUF" offLabel="AUS"></ejs-switch>
                            </div>
                        </div>
                    </div>

                    <!-- Custom Settings Section -->
                    <div class="mb-3">
                        <div class="d-flex">
                            <label class="text-secondary fw-bold">Custom Settings</label>
                        </div>
                        <div class="d-flex flex-wrap">
                            <div class="col-12 col-md-6">
                                <div class="d-flex align-items-center p-1">
                                    <div class="col-10 col-md-8">
                                        <label class="fs-default text-muted">Disable Push Notifications on mobile devices</label>
                                    </div>
                                    <div class="col-2 justify-content-end d-flex">
                                        <ejs-switch onLabel="AUF" offLabel="AUS"></ejs-switch>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="d-flex align-items-center p-1">
                                    <div class="col-10 col-md-8">
                                        <label class="fs-default text-muted">Disable E-mail Notifications</label>
                                    </div>
                                    <div class="col-2 justify-content-end d-flex">
                                        <ejs-switch onLabel="AUF" offLabel="AUS"></ejs-switch>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="d-flex align-items-center p-1">
                                    <div class="col-10 col-md-8">
                                        <label class="fs-default text-muted">Disable Website Notifications</label>
                                    </div>
                                    <div class="col-2 justify-content-end d-flex">
                                        <ejs-switch onLabel="AUF" offLabel="AUS"></ejs-switch>
                                    </div>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <div class="d-flex align-items-center p-1">
                                    <div class="col-10 col-md-8">
                                        <label class="fs-default text-muted">Disable Website Chat Notifications</label>
                                    </div>
                                    <div class="col-2 justify-content-end d-flex">
                                        <ejs-switch onLabel="AUF" offLabel="AUS"></ejs-switch>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="mb-3">
                        <div class="d-flex mb-2">
                            <app-headline [type]="'h5'" [class]="'text-capitalize text-muted fs-default fw-bold'" [title]="('ASSIGNEDINGROUPS' | translate)"></app-headline>
                        </div>
                        <div class="row row-cols-2 row-cols-sm-3 row-cols-md-3 row-cols-lg-3 row-cols-xl-4 justify-content-start g-2 p-sm-0">
                            <div class="d-flex">
                                <div class="group-card flex-grow-1">
                                    <div class="e-avatar e-avatar-circle e-avatar-xsmall m-1">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                    </div>
                                    <span class="link-text fs-small">Admin</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="group-card flex-grow-1">
                                    <div class="e-avatar e-avatar-circle e-avatar-xsmall m-1">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                    </div>
                                    <span class="link-text fs-small">UFU</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="group-card flex-grow-1">
                                    <div class="e-avatar e-avatar-circle e-avatar-xsmall m-1">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                    </div>
                                    <span class="link-text fs-small">HOTEL</span>
                                </div>
                            </div>
                            <div class="d-flex">
                                <div class="group-card flex-grow-1">
                                    <div class="e-avatar e-avatar-circle e-avatar-xsmall m-1">
                                        <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                    </div>
                                    <span class="link-text fs-small">Azubis</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="dialogs">
        <!-- !!!!!!!NEW DIALOG TYPE!!!!!!!! for MY FILES -->
        <ejs-dialog target=".dialogs" class="selector-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #myFilesDialog header="" [position]='position' [isModal]="true" [allowDragging]="false" [width]="'70%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="false">
            <ng-template #header class="p-1">
                <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex align-items-center">
                        <app-headline [title]="('MYFIELS' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
                        <ejs-tooltip #helpTooltip [content]="('MYFILESTOOLTIP' | translate)" width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2 d-block d-md-none" opensOn='Click' [animation]="'Fade'">
                            <span class="mts-icon cursor-pointer">help</span>
                        </ejs-tooltip>
                    </div>
                    <app-form-button (click)="closeDialog('my-files')" [style]="'btn'" [cssClass]="'btn-close'"></app-form-button>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="base-content custom-scrollbar d-flex flex-wrap mh-100 overflow-y-auto overflow-md-hidden">
                    <!-- Left Column -->
                    <div class="col-md-8 col-12 d-flex flex-column flex-grow-1 left-container h-100 overflow-y-auto p-3">
                        <div class="d-none justify-content-between align-items-center mb-3 d-md-flex">
                            <div class="d-flex align-items-center">
                                <app-headline [title]="('MYFIELS' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
                                <ejs-tooltip content='Some help tooltip' width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2 d-none d-md-block" opensOn='Click' [animation]="'Fade'">
                                    <span class="mts-icon cursor-pointer">help</span>
                                </ejs-tooltip>
                            </div>
                            <app-form-button (click)="closeDialog('my-files')" [style]="'btn'" [cssClass]="'btn-close'"></app-form-button>
                        </div>
                        <app-form-input [type]="'search'" [placeholder]="('SEARCH' | translate)" [iconFront]="'search'" [iconBack]="'close'" [iconClickType]="'back'" [fCN]="'search_string'" [fG]="loginForm">
                            <app-form-dropdown [fG]="loginForm" [fCN]="'filter_options'" [dataSource]="fileFolderFilter" [placeholder]="'SELECTFILTER' | translate"></app-form-dropdown>
                        </app-form-input>
                        
                        <span class="text-secondary fs-small"><b>25</b> results for your search</span>
                        <div class="d-inline-flex align-items-center gap-1 mt-2">
                            <app-form-button [text]="'BACK' | translate" (click)="onFakeClick()" [cssClass]="'e-outline e-small fw-bold'" [icon]="'chevron_left'" [iconPosition]="'left'"></app-form-button>
                            <app-headline [title]="'Current folder name'" [type]="'h6'" [class]="'text-secondary truncate-text fs-default'"></app-headline>
                        </div>
                        <div class="d-flex justify-content-end gap-1 my-2">
                            <div class="small-filter-dropdown">
                                <ejs-dropdownlist popupWidth='auto' [dataSource]="fileTypeFilter" [fields]="fileTypeFilterFields" [placeholder]="'Select Filter'" [sortOrder]="'Ascending'" [value]="fileTypeFilterSelectedValue" class="filter-dropdown">
                                    <ng-template #valueTemplate let-data="">
                                        <div class="d-flex align-items-center text-muted">
                                            <span class="mts-icon p-1">filter_list</span>
                                            <span class='value fs-small'>{{data.text}}</span>
                                        </div>
                                    </ng-template>
                                </ejs-dropdownlist>
                            </div>
                            <div class="small-filter-dropdown">
                                <ejs-dropdownlist popupWidth='auto' [dataSource]="fileFolderTypeFilter" [fields]="fileFolderTypeFilterFields" [placeholder]="'Select Filter'" [sortOrder]="'Ascending'" [value]="fileFolderTypeFilterSelectedValue" class="filter-dropdown">
                                    <ng-template #valueTemplate let-data="">
                                        <div class="d-flex align-items-center text-muted">
                                            <span class="mts-icon p-1">filter_list</span>
                                            <span class='value fs-small'>{{data.text}}</span>
                                        </div>
                                    </ng-template>
                                </ejs-dropdownlist>
                            </div>
                        </div>

                        <!-- Scrollable Form Container -->
                        <div class="custom-scrollbar overflow-y-auto overflow-sm-hidden max-h-100-md max-h-350">
                            <div class="d-flex h-100 mb-3 flex-column">
                                <div class="fileFolderListContainer d-flex flex-wrap gap-2 p-2">
                                    <!-- Folder Item -->
                                    <div class="file-card d-flex flex-column justify-content-between overflow-hidden" (click)="onFakeClick()">
                                        <div class="folder-icon-section bg-lightgrey d-flex align-items-center justify-content-center">
                                            <span class="file-folder-icon-size mts-icon mts-icon-filled text-warning">folder</span>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center flex-grow-1 px-2 py-1">
                                            <ejs-tooltip content="My Templates" position="BottomCenter">
                                                <span class="file-title truncate-text text-center overflow-hidden fs-small text-muted">My Templates</span>
                                            </ejs-tooltip>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-end px-2 py-1">
                                            <span class="file-info fs-small text-secondary"><b>10</b> files</span>
                                        </div>
                                    </div>
  
                                    <!-- Folder Item -->
                                    <div class="file-card d-flex flex-column justify-content-between overflow-hidden" (click)="onFakeClick()">
                                        <div class="folder-icon-section bg-lightgrey d-flex align-items-center justify-content-center">
                                            <span class="file-folder-icon-size mts-icon mts-icon-filled text-warning">folder</span>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center px-2 py-1">
                                            <ejs-tooltip content="Lorem impsum dolor sit amet contigur" position="BottomCenter">
                                                <span class="file-title truncate-text text-center overflow-hidden fs-small text-muted">Lorem impsum dolor sit amet contigur</span>
                                            </ejs-tooltip>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-end px-2 py-1">
                                            <span class="file-info fs-small text-secondary"><b>12</b> files</span>
                                        </div>
                                    </div>
  
                                    <!-- Image Item -->
                                    <div class="file-card selected d-flex flex-column justify-content-between overflow-hidden" (click)="onFakeClick()">
                                        <div class="file-icon-section position-relative d-flex align-items-center justify-content-center">
                                            <img src="https://i.pinimg.com/564x/3f/e0/c6/3fe0c626f3330adb28c6b2d20b973d52.jpg" class="file-image" alt="Preview" />
                                            <span class="mts-icon text-white fs-5 position-absolute top-0 end-0 me-2 me-sm-1 mt-1 mt-sm-0" (click)="onOpenDialog($event,'preview-image')">visibility</span>
                                            <!-- Selected Icon -->
                                            <span class="selected-icon mts-icon fs-5 position-absolute bottom-0 end-0 me-2 me-sm-1 mb-1 mb-sm-0">task_alt</span>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center flex-grow-1 px-2 py-1">
                                            <ejs-tooltip content="Company Party.jpg" position="BottomCenter">
                                                <span class="file-title truncate-text text-center overflow-hidden fs-small text-muted">Company Party.jpg</span>
                                            </ejs-tooltip>
                                        </div>
                                    </div>
                                    
                                    <!-- Image Item -->
                                    <div class="file-card d-flex flex-column justify-content-between overflow-hidden" (click)="onFakeClick()">
                                        <div class="file-icon-section position-relative align-items-center justify-content-center">
                                            <img src="https://i.pinimg.com/564x/3f/e0/c6/3fe0c626f3330adb28c6b2d20b973d52.jpg" class="file-image" alt="Preview" />
                                            <span class="mts-icon text-white fs-5 position-absolute top-0 end-0 me-2 me-sm-1 mt-1 mt-sm-0" (click)="onOpenDialog($event,'preview-image')">visibility</span>
                                            <!-- Selected Icon -->
                                            <span class="selected-icon mts-icon fs-5 position-absolute bottom-0 end-0  me-2 me-sm-1 mb-1 mb-sm-0">task_alt</span>
                                        </div>
                                        <div class="d-flex justify-content-center align-items-center flex-grow-1 px-2 py-1">
                                            <ejs-tooltip content="Company_Party_Longer_File_Name_Test.jpg" position="BottomCenter">
                                                <span class="file-title truncate-text text-center overflow-hidden fs-small text-muted">Company_Party_Longer_File_Name_Test.jpg</span>
                                            </ejs-tooltip>
                                        </div>
                                    </div>  
                                </div>
                            </div>
                        </div>    
                    </div>

                    <!-- Right Column -->
                    <div class="col-md-4 col-12 d-flex flex-column right-container h-100 p-3">
                        <div class="text-center mb-3">
                            <app-headline [title]="('SELECTEDFILES' | translate)" [type]="'h5'"></app-headline>
                        </div>
                        <!-- Scrollable Steps List -->
                        <div class="custom-scrollbar overflow-sm-hidden">
                            <div class="d-flex flex-wrap gap-2 uploadedFilesContainer">
                                <div class="uploaded-files-list" (click)="onFakeClick()">
                                    <!-- Image Preview -->
                                    <img src="https://i.pinimg.com/564x/3f/e0/c6/3fe0c626f3330adb28c6b2d20b973d52.jpg" alt="Uploaded Image" class="upload-image" />
                                
                                    <!-- Trash Icon Overlay -->
                                    <div class="overlay d-flex align-items-center">
                                        <!-- Left Arrow -->
                                        <span class="mts-icon text-white left-arrow" (click)="onFakeClick()">chevron_left</span> 
        
                                        <!-- Trash Icon -->
                                        <span class="mts-icon text-white trash-icon" (click)="onFakeClick()">delete</span>
        
                                        <!-- Right Arrow -->
                                        <span class="mts-icon text-white right-arrow" (click)="onFakeClick()">chevron_right</span>                                
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Save Button (Hidden on Mobile) -->
                        <div class="save-button-container text-center mt-3 d-none d-md-block">
                            <app-form-button [text]="'CONTINUE' | translate" [disabled]="loginForm.invalid" (click)="onFakeClick()" [cssClass]="'e-primary w-100'"></app-form-button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <div class="mt-auto d-md-none col-12 d-flex justify-content-between navigation-buttons-mobile p-2">
                    <app-form-button [text]="'CONTINUE' | translate" [disabled]="loginForm.invalid" (click)="onFakeClick()" [cssClass]="'e-primary mx-auto w-100'"></app-form-button>
                </div>
            </ng-template>
        </ejs-dialog>

         <!-- Dialog for Editing Avatar -->
         <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showChangeAvatar [position]='position' [isModal]="true" [allowDragging]="false" [width]="'40%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <app-headline [title]="('MANAGEAVATARIMAGE' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
                    <ejs-tooltip #helpTooltip [content]="('MANAGEAVATARIMAGETOOLTIP' | translate)" width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column h-100 p-3">
                    <div class="d-flex justify-content-between align-items-center mb-3">
                        <app-headline [type]="'h6'" [title]="('UPLOADIMAGE' | translate)" [class]="'text-secondary'"></app-headline>
                        <div class="dropdown">
                            <button class="btn btn-link p-0 text-muted" id="changeAvatarUploadOptions" data-bs-toggle="dropdown" aria-expanded="false">
                                <span class="mts-icon">more_horiz</span>
                            </button>
                            <ul class="dropdown-menu dropdown-menu-end" aria-labelledby="changeAvatarUploadOptions">
                                <li><a class="dropdown-item" href="#" (click)="onChooseFileClicked($event)">Upload Image</a></li>
                                <li><a class="dropdown-item" href="#" (click)="onOpenDialog($event,'my-files')">My Files</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class='fileUploadArea mb-3'>
                        <div class="card">
                            <div class="card-body">
                                <div class="d-flex mx-auto col-12 col-md-6 col-xl-10 justify-content-center">
                                    <div class="d-flex flex-column">
                                        <div class="d-flex align-items-center">
                                            <span class="upload-icon fs-1 mts-icon text-secondary me-3">cloud_upload</span>
                                            <span class="upload-text fs-6 text-secondary">
                                                Drop files here to upload or
                                            </span>
                                            <app-form-button (click)="onChooseFileClicked($event)" [style]="'btn'" [cssClass]="'upload-choose-file btn-link text-secondary fw-bold fs-default px-0 py-1'" [text]="'choose file'"></app-form-button>   
                                        </div>
                                        <span *ngIf="fileUploadFailed" class="fs-6 e-error text-center">
                                            File Upload failed!
                                        </span>
                                        <div class="progress w-auto">
                                            <div class="progress-bar" role="progressbar" aria-label="File upload progrss" [style.width.%]="progressValue"
                                            [attr.aria-valuenow]="progressValue" aria-valuemin="0" aria-valuemax="100">{{ progressValue }}%</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ejs-uploader #previewupload class='fileuploader' sequentialUpload='true' multiple='true' [asyncSettings]='path'
                            [dropArea]='dropElement' [allowedExtensions]='allowExtensions' (selected)='onSelect($event)'
                            (progress)="onFileUploadProgress($event)" (success)="onUploadComplete()" (failure)='onUploadFailed($event)'>
                        </ejs-uploader>
                    </div>
                    <div class="custom-scrollbar">
                        <div class="d-flex flex-wrap gap-2 uploadedFilesContainer">
                            <div *ngFor="let file of filesDetails; let i = index" class="uploaded-files-list" (click)="onFileClick(file)">
                            <!-- Image Preview -->
                            <img [src]="file.preview" alt="Uploaded Image" class="upload-image" />
                        
                            <!-- Trash Icon Overlay -->
                            <div class="overlay d-flex align-items-center">
                                <!-- Left Arrow -->
                                <span *ngIf="i > 0" class="mts-icon text-white left-arrow" (click)="moveFileLeft(file, $event)">chevron_left</span> 

                                <!-- Trash Icon -->
                                <span class="mts-icon text-white trash-icon" (click)="removeFile(file, $event)">delete</span>

                                <!-- Right Arrow -->
                                <span *ngIf="i < filesDetails.length - 1" class="mts-icon text-white right-arrow" (click)="moveFileRight(file, $event)">chevron_right</span>                                
                            </div>
                            </div>
                        </div>    
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <div class="d-flex align-items-center justify-content-center p-2">
                    <app-form-button [text]="'SAVE' | translate" [disabled]="loginForm.invalid" (click)="onFakeClick()" [cssClass]="'e-primary mx-auto w-50'"></app-form-button>
                </div>
            </ng-template>
        </ejs-dialog>

        <!-- Dialog for Credentials -->
        <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showChangeCredentials [position]='position' [isModal]="true" [allowDragging]="false" [width]="'30%'" [height]="'40vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <app-headline [title]="('MANAGECREDENTIALS' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
                    <ejs-tooltip #helpTooltip [content]="('MANAGECREDENTIALSTOOLTIP' | translate)" width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column col-12 mx-auto justify-content-center pt-4 p-3">
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <app-form-label [label]="'USERNAME' | translate"></app-form-label>
                            <app-form-input [placeholder]="('USERNAME' | translate)" [errorText]="('USERNAMEREQUIRED' | translate)" [fCN]="'user_name'" [fG]="loginForm"></app-form-input>
                        </div>
                    </div>   
                    <div class="row mb-2">
                        <div class="col-md-12">
                            <app-form-label [label]="'PASSWORD' | translate"></app-form-label>
                            <app-form-input [type]="'password'" [placeholder]="('PASSWORD' | translate)" [errorText]="('PASSWORDREQUIRED' | translate)" [fCN]="'password'" [fG]="loginForm"></app-form-input>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <div class="d-flex align-items-center justify-content-center p-2">
                    <app-form-button [text]="'SAVE' | translate" [disabled]="loginForm.invalid" (click)="onFakeClick()" [cssClass]="'e-primary mx-auto w-50'"></app-form-button>
                </div>
            </ng-template>
        </ejs-dialog>

         <!-- Dialog for Custom Informations -->
         <ejs-dialog target=".dialogs" class="simple-dialog" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showCustomInformations [position]='position' [isModal]="true" [allowDragging]="false" [width]="'30%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true">
            <ng-template #header>
                <div class="d-flex align-items-center justify-content-center">
                    <app-headline [title]="('CUSTOMINFORMATIONS' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
                    <ejs-tooltip #helpTooltip [content]="('CUSTOMINFORMATIONSTOOLTIP' | translate)" width='300px' height='100px' isSticky='true' class="ms-2" cssClass="helpTooltip p-2" opensOn='Click' [animation]="'Fade'">
                        <span class="mts-icon cursor-pointer">help</span>
                    </ejs-tooltip>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column col-12 mx-auto pt-4 p-3 custom-scrollbar h-100 overflow-y-auto">
                    <app-headline [title]="('NOCUSTOMINFOAVAILABLE' | translate)" [type]="'h6'" [class]="'text-center text-secondary'"></app-headline>
                    <div class="row mb-1">
                        <div class="col-md-12">
                            <app-form-label [label]="'POSITION' | translate"></app-form-label>
                            <app-form-input [placeholder]="('POSITION' | translate)" [fCN]="'position'" [fG]="loginForm"></app-form-input>
                        </div>
                    </div>   
                    <div class="row mb-1">
                        <div class="col-md-12">
                            <app-form-label [label]="'COMPANY' | translate"></app-form-label>
                            <app-form-input [placeholder]="('COMPANY' | translate)" [fCN]="'company'" [fG]="loginForm"></app-form-input>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #footerTemplate>
                <div class="d-flex align-items-center justify-content-center p-2">
                    <app-form-button [text]="'SAVE' | translate" [disabled]="loginForm.invalid" (click)="onFakeClick()" [cssClass]="'e-primary mx-auto w-50'"></app-form-button>
                </div>
            </ng-template>
        </ejs-dialog>

        <!-- Full screen dialog for image preview -->
        <ejs-dialog target=".dialogs" class="simple-dialog e-dlg-fullscreen" [visible]='false' [animationSettings]='animationSettings' [resizeHandles]='resizeHandleDirection' #showFullScreenDialog [position]='position' [isModal]="true" [allowDragging]="false" [zIndex]="999" [showCloseIcon]="false">
            <ng-template #header>
                <div class="d-flex justify-content-between align-items-center w-100">
                    <div class="d-flex align-items-center">
                        <span class="mts-icon fs-5">draft</span>
                        <app-headline [title]="'pexels-photo-2834173.jpeg'" [type]="'h6'" [class]="'fs-small truncate-text'"></app-headline>
                    </div>
                    <div class="d-flex align-items-center">
                        <div class="p-1">
                            <button type="button" class="btn btn-link text-muted p-1">
                                <span class="mts-icon fs-5">download</span>
                            </button>
                        </div>
                        <div class="p-1">
                            <button type="button" class="btn btn-link text-muted p-1">
                                <span class="mts-icon fs-5">mail</span>
                            </button>
                        </div>
                        <div class="p-1">
                            <button type="button" class="btn btn-link text-muted p-1" (click)="togglePopup('link')">
                                <span class="mts-icon fs-5">link</span>
                            </button>
                        </div>
                        <div class="p-1">
                            <button type="button" class="btn btn-link text-muted p-1" (click)="togglePopup('info')">
                                <span class="mts-icon fs-5">info</span>
                            </button>
                        </div>
                        <div class="p-1">
                            <app-form-button (click)="closeDialog('preview-image')" [style]="'btn'" [cssClass]="'btn-close'"></app-form-button>
                        </div>
                    </div>
                </div>
            </ng-template>
            <ng-template #content>
                <div class="d-flex flex-column h-100 align-items-center justify-content-center position-relative">
                    <div class="imagepreview-fullscreen ">
                        <img src="https://printpanoramics.co.uk/cdn/shop/articles/large-canvas-print.jpg?v=1678653665&width=2000" alt="Preview" />
                    
                        <!-- Left Overlay -->
                        <div class="overlay overlay-left position-absolute d-flex align-items-center justify-content-center cursor-pointer" (click)="onFakeClick()">
                            <button type="button" class="btn btn-link px-1 arrow-button">
                                <span class="mts-icon fs-3">chevron_left</span>
                            </button>
                        </div>
                    
                        <!-- Right Overlay -->
                        <div class="overlay overlay-right position-absolute d-flex align-items-center justify-content-center cursor-pointer" (click)="onFakeClick()">
                            <button type="button" class="btn btn-link px-1 arrow-button">
                                <span class="mts-icon fs-3">chevron_right</span>
                            </button>
                        </div>
                    </div>

                    <!-- Link Popup -->
                    <div *ngIf="showLinkPopup" class="card imagepreview-iconpopup link-popup position-absolute p-3">
                        <app-headline [title]="('PUBLICLINK' | translate)" [type]="'h6'" [class]="'pb-3'"></app-headline>
                        <form>
                            <div class="row mb-3">
                                <div class="col-md-12">
                                    <app-form-label [label]="'YOURLINK' | translate"></app-form-label>
                                    <app-form-input [fCN]="'image_link'" [fG]="loginForm" value="https://stage.max-toolbox.com/intranet/detail/13779153" [iconBack]="'content_copy'" [iconClickType]="'back'"></app-form-input>
                                </div>
                            </div>
                            <app-headline [title]="('ADDITIONALOPTIONS' | translate)" [type]="'h6'" [class]="'fs-default fw-bold text-capitalize text-muted pb-2'"></app-headline>
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <app-form-label [label]="'EXPIRESAT' | translate"></app-form-label>
                                    <ejs-datetimepicker class="w-100" id="expiresAt" [locale]="'de-DE'" format="dd.MM.yyyy HH:mm" step="15" [timeFormat]="'HH:mm'"></ejs-datetimepicker>
                                </div>
                            </div>
                            <div class="row mb-2">
                                <div class="col-md-12">
                                    <app-form-label [label]="'PASSWORDPROTECTION' | translate"></app-form-label>
                                    <app-form-input [type]="visibility ? 'text' : 'password'" [placeholder]="('PASSWORD' | translate)" [errorText]="('PASSWORDREQUIRED' | translate)" [iconFront]="'key'" [iconBack]="visibility ? 'visibility' : 'visibility_off'" [iconClickType]="'back'" [fCN]="'password'" [fG]="loginForm" (iconBackClick)="visibility = !visibility"></app-form-input>
                                </div>
                            </div>
                            <div class="d-flex justify-content-end my-2">
                                <app-form-button [text]="'SAVEOPTIONS' | translate" (click)="onFakeClick()" [cssClass]="'e-primary fs-small py-0'"></app-form-button>
                            </div>
                        </form>
                    </div>

                    <!-- Info Popup -->
                    <div *ngIf="showInfoPopup" class="card imagepreview-iconpopup info-popup position-absolute p-3">
                        <app-headline [title]="('PICTUREINFO' | translate)" [type]="'h6'" [class]="'pb-3'"></app-headline>
                        <div class="d-flex align-items-center">
                            <div class="d-flex align-items-center px-1">
                                <div class="e-avatar e-avatar-circle e-avatar-small m-1">
                                    <img src="https://ej2.syncfusion.com/demos/src/grid/images/2.png" alt="profile_pic" width="40" height="40">
                                </div>
                            </div>
                            <div class="flex-grow-1 lh-1">
                                <app-headline [title]="'Stefan Dragosici'" [type]="'h6'" [class]="'text-muted truncate-text fs-small'"></app-headline>
                                <small class="text-light fs-verysmall">UFU</small>
                            </div>
                        </div>
                        <app-headline [title]="'pexels-photo-2834173.jpeg'" [type]="'h6'" [class]="'fs-small fw-bold pt-2'"></app-headline>
                        <app-headline [title]="'Uploaded: 24 May 2024 13:35'" [type]="'h6'" [class]="'fs-verysmall text-light'"></app-headline>
                        <app-headline [title]="'Size: 1.3MB'" [type]="'h6'" [class]="'fs-small text-muted pt-2'"></app-headline>
                        <app-headline [title]="'Downloads: 10'" [type]="'h6'" [class]="'fs-small text-muted'"></app-headline>
                    </div>                  
                </div>
            </ng-template>
        </ejs-dialog>
    </div>

</ng-container>
