import {Component, EventEmitter, Input, Output} from '@angular/core';
import {NgForOf} from "@angular/common";

@Component({
  selector: 'app-step-content',
  standalone: true,
  imports: [
    NgForOf
  ],
  templateUrl: './step-content.component.html',
  styleUrl: './step-content.component.scss'
})
export class StepContentComponent {

  @Input() steps: string[] = [];
  @Input() activeStep: number = 0;
  @Output() activeStepChange: EventEmitter<number> = new EventEmitter<number>();

  public setStep(activeStep: number): void {
    this.activeStep = activeStep;
    this.activeStepChange.emit(this.activeStep);
  }

}
