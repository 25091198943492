import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {FormInputComponent} from "../form-input/form-input.component";
import {TranslatePipe} from "../../pipes/translate.pipe";
import {FormLabelComponent} from "../form-label/form-label.component";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {TYPE} from "../../types/types";
import {Subscription} from "rxjs";
import {ApiService} from "../../services/api.service";
import {LoadingService} from "../../services/loading.service";
import {FormDropdownComponent} from "../form-dropdown/form-dropdown.component";
import {LanguageService} from "../../services/language.service";
import {IconBadgeComponent} from "../icon-badge/icon-badge.component";
import {LinkComponent} from "../link/link.component";
import {NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {FormCheckboxComponent} from "../form-checkbox/form-checkbox.component";
import {Dialog, TooltipModule} from "@syncfusion/ej2-angular-popups";
import {DialogUtility} from "@syncfusion/ej2-popups";
import {FormButtonComponent} from "../form-button/form-button.component";
import {TooltipComponent} from "../tooltip/tooltip.component";
import {HeadlineComponent} from "../headline/headline.component";
import {ContentComponent} from "../content/content.component";

@Component({
  selector: 'app-form-user-group-mail',
  standalone: true,
  imports: [
    DropDownListModule,
    FormInputComponent,
    TranslatePipe,
    FormLabelComponent,
    ReactiveFormsModule,
    FormDropdownComponent,
    IconBadgeComponent,
    LinkComponent,
    NgForOf,
    FormCheckboxComponent,
    TooltipModule,
    FormButtonComponent,
    TooltipComponent,
    NgIf,
    HeadlineComponent,
    ContentComponent,
    NgOptimizedImage
  ],
  templateUrl: './form-user-group-mail.component.html',
  styleUrl: './form-user-group-mail.component.scss'
})
export class FormUserGroupMailComponent implements OnInit, OnDestroy {

  @Input() public user: number[] = [];
  @Input() public groups: number[] = [];
  @Input() public type: 'own' | 'all' | 'active' | 'inactive' | 'registration' = 'own'; // TODO: too many types, should be simplified
  @Input() public method: 'user' | 'group' | 'userGroup' = 'userGroup';
  @Input() public allowEmails: boolean = false;
  @Input() public allowPush: boolean = false;
  @Input() public readonly: boolean = false;
  @Output() public userChange = new EventEmitter<number[]>();
  @Output() public groupsChange = new EventEmitter<number[]>();
  @Output() public mailsChange = new EventEmitter<string[]>();
  @Output() public pushUserIDsChange = new EventEmitter<number[]>();
  @Output() public pushGroupIDsChange = new EventEmitter<number[]>();

  public userGroupOptions: any[] = [];
  public userGroupMailForm: FormGroup = this.formBuilder.group({
    userGroup: [''],
    addMail: ['', [Validators.email]],
    userGroupMailFilter: ['all']
  });
  public userGroupMailFilter: any[] = [];
  public participants: (TYPE.FieldSettingsModelWithId)[] = [];
  private subscriptions: Subscription[] = [];
  private page: number = 0;
  private searchString: string = '';
  private currentLoadingType: 'user' | 'group' = 'group';
  private dialogInstance: Dialog | null = null;

  constructor(
    private formBuilder: FormBuilder,
    private apiService: ApiService,
    private loadingService: LoadingService,
    private languageService: LanguageService
  ) {
  }

  public ngOnInit(): void {
    if (this.method === 'user') {
      this.currentLoadingType = 'user';
    }

    if (this.allowEmails) {
      this.userGroupMailFilter = [
        {id: 'all', name: this.languageService.getTranslationByKey('SHOWALL')},
        {id: 'mail', name: this.languageService.getTranslationByKey('SHOWMAILONLY')},
        {id: 'group', name: this.languageService.getTranslationByKey('SHOWGROUPONLY')},
        {id: 'user', name: this.languageService.getTranslationByKey('SHOWUSERONLY')}
      ];
    } else {
      this.userGroupMailFilter = [
        {id: 'all', name: this.languageService.getTranslationByKey('SHOWALL')},
        {id: 'group', name: this.languageService.getTranslationByKey('SHOWGROUPONLY')},
        {id: 'user', name: this.languageService.getTranslationByKey('SHOWUSERONLY')}
      ];
    }

    this.load();
    this.loadInitials();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  private load(): void {
    if (this.currentLoadingType === 'group') {
      this.loadGroups();
    } else {
      if (this.method === 'user' || this.method === 'userGroup') {
        this.loadUser();
      }
    }
  }

  private loadGroups(): void {
    this.subscriptions.push(this.apiService.getGroups(this.page, this.searchString, true, this.type).subscribe((groupListItems) => {
      let groupListItemsTmp = groupListItems.map((group) => {
        return {
          id: group.id,
          description: group.description,
          count: group.count,
          image: group.image,
          name: group.name,
          type: 'group'
        };
      });

      if (!this.userGroupOptions.length) {
        this.userGroupOptions = groupListItemsTmp;
      } else {
        this.userGroupOptions = this.userGroupOptions.concat(groupListItemsTmp);
      }

      if (!groupListItemsTmp.length) {
        this.currentLoadingType = 'user';
        this.page = 0;
        this.load();
      }

      this.removeExistingParticipants();
    }));
  }

  private loadUser(): void {
    this.subscriptions.push(this.apiService.getUsers(this.page, this.searchString, this.type).subscribe((userListItems) => {
      let userListItemsTmp = userListItems.map((user) => {
        return {
          id: user.id,
          image: user.avatar,
          name: user.last_name + ', ' + user.first_name,
          type: 'user'
        };
      });

      if (userListItemsTmp.length) {
        this.userGroupOptions = this.userGroupOptions.concat(userListItemsTmp);
      }

      this.removeExistingParticipants();
    }));
  }

  private removeExistingParticipants(): void {
    this.userGroupOptions = this.userGroupOptions.filter((item) => {
      return !this.participants.find((participant) => participant.id === item.id && participant.type === item.type);
    });
  }

  public loadNextPage(): void {
    if (!this.loadingService.getLoadingIndicator()) {
      this.page++;
      this.load();
    }
  }

  public search(searchString: any): void {
    if (!this.loadingService.getLoadingIndicator()) {
      if (this.method !== 'user') {
        this.currentLoadingType = 'group';
      }
      this.searchString = searchString;
      this.page = 0;
      this.userGroupOptions = [];
      this.load();
    }
  }

  public addUserGroup(userOrGroup: string|number|TYPE.FieldSettingsModelWithId): void {
    if (typeof userOrGroup === 'string' || typeof userOrGroup === 'number') {
      userOrGroup = this.userGroupOptions.find((item) => item.id === userOrGroup) as TYPE.FieldSettingsModelWithId;
    }
    if (this.participants.find((item) => item.id === userOrGroup.id && item.type === userOrGroup.type)) {
      // already added
    } else {
      this.participants.push(userOrGroup);
      this.userGroupMailForm.addControl(userOrGroup.type! + userOrGroup.id, this.formBuilder.control(false));
    }
    setTimeout(() => {
      this.userGroupMailForm.controls['userGroup'].setValue(null);
      this.removeExistingParticipants();
      this.updateEmitters();
    }, 500);
  }

  public addMail(): void {
    if (this.userGroupMailForm.controls['addMail'].value && this.userGroupMailForm.controls['addMail'].valid && !this.participants.find((item) => item.id === this.userGroupMailForm.controls['addMail'].value && item.type === 'mail')) {
      this.participants.push({
        id: this.userGroupMailForm.controls['addMail'].value,
        name: this.userGroupMailForm.controls['addMail'].value,
        type: 'mail'
      });
      this.userGroupMailForm.addControl('mail' + this.userGroupMailForm.controls['addMail'].value, this.formBuilder.control(false));
      this.userGroupMailForm.controls['addMail'].setValue('');
      this.updateEmitters();
    }
  }

  public selectAll(): void {
    this.participants.forEach((item) => {
      this.userGroupMailForm.controls[item.type! + item.id].setValue(true);
    });
  }

  public removeSelected(): void {
    this.participants.forEach((item) => {
      if (this.userGroupMailForm.controls[item.type! + item.id].value) {
        this.userGroupMailForm.removeControl(item.type! + item.id);
      }
    });
    this.participants = this.participants.filter((item) => this.userGroupMailForm.controls[item.type! + item.id]);
    this.updateEmitters();
  }

  public showDeleteOverlay(item: TYPE.FieldSettingsModelWithId): void {
    this.dialogInstance = DialogUtility.confirm({
      title: '<h6 class="text-danger fw-bold m-0 pt-1">' + this.languageService.getTranslationByKey('CONFIRMITEMDELETE') + '</h6>',
      content: this.languageService.getTranslationByKey('AREYOUSUREYOUWANTTODELETE'),
      cancelButton: {
        text: this.languageService.getTranslationByKey('CANCEL'),
        click: () => this.showDeleteOverlayCancel()
      },
      okButton: {
        text: this.languageService.getTranslationByKey('DELETE'),
        click: () => this.showDeleteOverlayConfirmed(item),
        cssClass: 'bg-danger brd-danger'
      },
      showCloseIcon: true,
      closeOnEscape: true,
      position: {X: 'center', Y: 'center'},
      animationSettings: {effect: 'Fade'},
      zIndex: 9999
    });
  }

  private showDeleteOverlayConfirmed(item: TYPE.FieldSettingsModelWithId): void {
    this.userGroupMailForm.removeControl(item.type! + item.id);
    this.participants = this.participants.filter((participant) => participant.id !== item.id || participant.type !== item.type);
    this.dialogInstance?.hide();
    this.updateEmitters();
  }

  private showDeleteOverlayCancel(): void {
    this.dialogInstance?.hide();
  }

  private loadInitials(): void {
    if (this.user && this.user.length) {
      this.user.forEach((userID) => {
        this.subscriptions.push(this.apiService.getUser(userID).subscribe((user) => {
          if (user) {
            this.participants.push({
              id: user.id,
              image: user.avatar,
              name: user.last_name + ', ' + user.first_name,
              type: 'user'
            });
            this.userGroupMailForm.addControl('user' + user.id, this.formBuilder.control(false));
          }
          this.removeExistingParticipants();
        }));
      });
    }

    if (this.groups && this.groups.length) {
      this.groups.forEach((groupID) => {
        this.subscriptions.push(this.apiService.getGroup(groupID).subscribe((group) => {
          if (group) {
            this.participants.push({
              id: group.id,
              description: group.description,
              count: group.users.length,
              image: group.image,
              name: group.name,
              type: 'group'
            });
            this.userGroupMailForm.addControl('group' + group.id, this.formBuilder.control(false));
          }
          this.removeExistingParticipants();
        }));
      });
    }
  }

  private updateEmitters(): void {
    this.userChange.emit(this.participants.filter((item) => item.type === 'user').map((item) => item.id as number));
    this.groupsChange.emit(this.participants.filter((item) => item.type === 'group').map((item) => item.id as number));
    this.mailsChange.emit(this.participants.filter((item) => item.type === 'mail').map((item) => item.id as string));
  }

  public togglePush(participant: TYPE.FieldSettingsModelWithId): void {
    participant.push = !participant.push;
    this.pushUserIDsChange.emit(this.participants.filter((item) => item.type === 'user' && item.push).map((item) => item.id as number));
    this.pushGroupIDsChange.emit(this.participants.filter((item) => item.type === 'group' && item.push).map((item) => item.id as number));
  }
}
