<div class="steps-list d-flex justify-content-between align-items-center w-100">
  <!-- Numbers of steps before the current step -->
  <div class="d-flex justify-content-start flex-shrink-0">
    <ng-container *ngFor="let step of steps; let i = index">
      <div class="{{ i === activeStep ? 'active-step' : i < activeStep ? 'completed-step' : 'inactive-step' }}" (click)="setStep(i)">
        <span *ngIf="i < activeStep" class="step-number d-flex justify-content-center align-items-center fs-small">
          {{ i + 1 }}
        </span>
      </div>
    </ng-container>
  </div>

  <!-- Current step and title -->
  <div class="d-flex flex-grow-1 active-step align-items-center">
    <span class="step-number me-2 d-flex justify-content-center align-items-center fs-small">{{ activeStep + 1 }}</span>
    <span class="step-title fs-small truncate-text">{{ steps[activeStep] }}</span>
  </div>

  <!-- Remaining steps numbers -->
  <div class="d-flex justify-content-end flex-shrink-0">
    <ng-container *ngFor="let step of steps; let i = index">
      <div class="{{ i === activeStep ? 'active-step' : i < activeStep ? 'completed-step' : 'inactive-step' }}" (click)="setStep(i)">
        <span *ngIf="i > activeStep" class="step-number d-flex justify-content-center align-items-center fs-small">
          {{ i + 1 }}
        </span>
      </div>
    </ng-container>
  </div>
</div>
