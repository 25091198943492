import {Component, EventEmitter, Input, Output} from '@angular/core';
import {DialogModule, TooltipModule} from "@syncfusion/ej2-angular-popups";
import {FormInputComponent} from "../../../shared/elements/form-input/form-input.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {DialogService} from "../../../shared/services/dialog.service";
import {FormBuilder, FormGroup} from "@angular/forms";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {ItemCalendarEventComponent} from "../item-calendar-event/item-calendar-event.component";
import {NgForOf, NgIf} from "@angular/common";
import {TYPE} from "../../../shared/types/types";
import {DialogCalendarEventComponent} from "../dialog-calendar-event/dialog-calendar-event.component";
import {DialogCalendarParticipantsComponent} from "../dialog-calendar-participants/dialog-calendar-participants.component";

@Component({
  selector: 'app-dialog-calendar-events',
  standalone: true,
  imports: [
    DialogModule,
    FormInputComponent,
    TooltipModule,
    TranslatePipe,
    TooltipComponent,
    HeadlineComponent,
    ItemCalendarEventComponent,
    NgForOf,
    DialogCalendarEventComponent,
    NgIf,
    DialogCalendarParticipantsComponent
  ],
  templateUrl: './dialog-calendar-events.component.html',
  styleUrl: './dialog-calendar-events.component.scss'
})
export class DialogCalendarEventsComponent {
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public calendarEventsForm: FormGroup = this.formBuilder.group({
    searchString: [''],
  });
  public calenderEvents: TYPE.CalendarEvent[] = [
    {
      name: 'Urgent meeting',
      priority: 'high',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      dateStart: new Date(),
      timeStart: new Date(),
      timeEnd: new Date(),
      frequency: 'once',
      onDateTimeEnd: new Date(),
      type: 'virtual',
      participantsUserIDs: [108],
      participantsGroupIDs: [8,6],
      participantsMails: ['cj@humanstars.app'],
      followUps: 2,
      isEditable: true
    },
    {
      name: 'Normal meeting',
      priority: 'medium',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      dateStart: new Date(),
      timeStart: new Date(),
      timeEnd: new Date(),
      frequency: 'once',
      onDateTimeEnd: new Date(),
      type: 'virtual',
      participantsUserIDs: [108],
      participantsGroupIDs: [8,6],
      participantsMails: ['cj@humanstars.app'],
      followUps: 10
    },
    {
      name: 'Low meeting',
      priority: 'low',
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.',
      dateStart: new Date(),
      timeStart: new Date(),
      timeEnd: new Date(),
      frequency: 'once',
      onDateTimeEnd: new Date(),
      type: 'virtual',
      participantsUserIDs: [108],
      participantsGroupIDs: [8,6],
      participantsMails: ['cj@humanstars.app'],
      isEditable: true
    }
  ];
  public calenderEventToEdit: TYPE.CalendarEvent | null = null;
  public calendarEventDialog: boolean = false;
  public calendarParticipantsDialog: boolean = false;

  constructor(
    public dialogService: DialogService,
    private formBuilder: FormBuilder
  ) {
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

  public edit(calenderEvent: TYPE.CalendarEvent): void {
    this.calenderEventToEdit = calenderEvent;
    this.calendarEventDialog = true;
  }

  public participants(calenderEvent: TYPE.CalendarEvent): void {
    this.calenderEventToEdit = calenderEvent;
    this.calendarParticipantsDialog = true;
  }

}
