import {Component, Input} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {DatePickerModule} from "@syncfusion/ej2-angular-calendars";
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {L10n} from "@syncfusion/ej2-base";
import {LanguageService} from "../../services/language.service";

@Component({
  selector: 'app-form-date',
  standalone: true,
  imports: [
    DatePickerModule,
    ReactiveFormsModule,
    DropDownListModule
  ],
  templateUrl: './form-date.component.html',
  styleUrl: './form-date.component.scss'
})
export class FormDateComponent {
  @Input() fCN: string = '';
  @Input() disabled: boolean = false;
  @Input() cssClass: string = '';
  @Input() placeholder: string = '';
  @Input() fG: FormGroup = new FormGroup({});

  constructor(
    private languageService: LanguageService
  ) {
    this.setSyncFusionTranslation();
  }

  private setSyncFusionTranslation(): void {
    L10n.load({
      [this.languageService.getLanguage()]: {
        'datepicker': {
          today: this.languageService.getTranslationByKey('TODAY')
        }
      },
    });
  }
}
