import {Component, OnDestroy, OnInit} from '@angular/core';
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {CustomerService} from "../../shared/services/customer.service";
import {UserService} from "../../shared/services/user.service";
import {DatePipe, NgForOf, NgIf, NgOptimizedImage} from "@angular/common";
import {LanguageService} from "../../shared/services/language.service";
import {LoadingService} from "../../shared/services/loading.service";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {IconBadgeComponent} from "../../shared/elements/icon-badge/icon-badge.component";
import {BarTopComponent} from "../helper/bar-top/bar-top.component";
import {BarFooterComponent} from "../helper/bar-footer/bar-footer.component";
import {Subscription} from "rxjs";
import {ApiService} from "../../shared/services/api.service";
import {TYPE} from "../../shared/types/types";
import {LinkComponent} from "../../shared/elements/link/link.component";
import {WidgetCalendarComponent} from "../calendar/widget-calendar/widget-calendar.component";
import {NotificationComponent} from "../../shared/elements/notification/notification.component";
import {ErrorService} from "../../shared/services/error.service";
import {DialogDashboardLinkComponent} from "./dialog-dashboard-link/dialog-dashboard-link.component";
import {DialogDashboardLinkManagerComponent} from "./dialog-dashboard-link-manager/dialog-dashboard-link-manager.component";
import {DialogDashboardIconsComponent} from "./dialog-dashboard-icons/dialog-dashboard-icons.component";
import {RouterService} from "../../shared/services/router.service";

@Component({
  selector: 'app-dashboard',
  standalone: true,
  imports: [
    BarTopComponent,
    TranslatePipe,
    NgOptimizedImage,
    HeadlineComponent,
    DatePipe,
    IconBadgeComponent,
    BarFooterComponent,
    NgIf,
    LinkComponent,
    NgForOf,
    WidgetCalendarComponent,
    NotificationComponent,
    DialogDashboardLinkComponent,
    DialogDashboardLinkManagerComponent,
    DialogDashboardIconsComponent
  ],
  providers: [
    TranslatePipe
  ],
  templateUrl: './dashboard.component.html',
  styleUrl: './dashboard.component.scss'
})
export class DashboardComponent implements OnInit, OnDestroy {

  public page: number = 1;
  public iconsAllowed: string[] = [];
  public iconsPageOne: string[] = [];
  public iconsPageTwo: string[] = [];
  public iconsBadges: TYPE.DashboardCounter = {} as TYPE.DashboardCounter;
  public dashboardIconsDialog: boolean = false;
  public dashboardLinkDialog: boolean = false;
  public dashboardLinkManagerDialog: boolean = false;
  public latestNotification: TYPE.Notification = {} as TYPE.Notification;
  public currentDate: Date = new Date();
  private subscriptions: Subscription[] = [];

  constructor(
    public loadingService: LoadingService,
    public customerService: CustomerService,
    public languageService: LanguageService,
    public userService: UserService,
    public routerService: RouterService,
    private apiService: ApiService,
    private errorService: ErrorService
  ) {
  }

  public ngOnInit(): void {
    this.subscriptions.push(this.apiService.getDashboardCounter().subscribe((dashboardCounter) => {
      this.iconsBadges = dashboardCounter;
    }));

    this.iconsAllowed = this.userService.getActiveModules().concat(this.userService.getAdministrativeRights());
    this.iconsPageOne = this.iconsAllowed.slice(0, 8);
    this.iconsPageTwo = this.iconsAllowed.slice(8, 16);
    this.load();
  }

  public ngOnDestroy(): void {
    this.subscriptions.forEach((subscription: Subscription) => subscription.unsubscribe());
  }

  public load(): void {
    this.subscriptions.push(this.apiService.getBell(0, 'new', null).subscribe({
      next: (messages) => {
        if (messages.length) {
          this.latestNotification = messages[0];
        }
      }, error: (error) => {
        this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, true);
      }
    }));
  }

  public openLink(link: TYPE.Link) {
    if (link.is_external) {
      this.routerService.openURL(link.value);
    } else {
      this.routerService.navigateTo(link.value);
    }
  }
}
