import {Component, Input, OnInit} from '@angular/core';
import {FormGroup, ReactiveFormsModule} from "@angular/forms";
import {NumericTextBoxModule} from "@syncfusion/ej2-angular-inputs";
import {DatePickerModule} from "@syncfusion/ej2-angular-calendars";

@Component({
  selector: 'app-form-numeric',
  standalone: true,
  imports: [
    NumericTextBoxModule,
    ReactiveFormsModule,
    DatePickerModule
  ],
  templateUrl: './form-numeric.component.html',
  styleUrl: './form-numeric.component.scss'
})
export class FormNumericComponent implements OnInit {
  @Input() fCN: string = '';
  @Input() disabled: boolean = false;
  @Input() cssClass: string = '';
  @Input() fG: FormGroup = new FormGroup({});

  public constructor() {
  }

  public ngOnInit(): void {
    if (this.disabled) {
      this.fG.controls[this.fCN].disable();
    }
  }
}
