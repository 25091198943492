import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CalendarService {

  constructor() {
  }


  public calculateEvents(): Date[] {
    let dateArray: Date[] = [];

    // Calculate events
    dateArray.push(new Date());
    dateArray.push(new Date());
    dateArray.push(new Date());

    return dateArray;
  }
}
