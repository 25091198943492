import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {TYPE} from "../../../shared/types/types";
import {ContentComponent} from "../../../shared/elements/content/content.component";
import {IconBadgeComponent} from "../../../shared/elements/icon-badge/icon-badge.component";
import {DatePipe, NgIf} from "@angular/common";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {LinkComponent} from "../../../shared/elements/link/link.component";
import {DialogUtility} from "@syncfusion/ej2-popups";
import {Dialog} from "@syncfusion/ej2-angular-popups";
import {LanguageService} from "../../../shared/services/language.service";

@Component({
  selector: 'app-item-calendar-event',
  standalone: true,
  imports: [
    HeadlineComponent,
    ContentComponent,
    IconBadgeComponent,
    DatePipe,
    FormButtonComponent,
    TranslatePipe,
    NgIf,
    LinkComponent
  ],
  templateUrl: './item-calendar-event.component.html',
  styleUrl: './item-calendar-event.component.scss',
  encapsulation: ViewEncapsulation.None
})
export class ItemCalendarEventComponent implements OnInit {
  @Input() type: 'default'|'widget' = 'default';
  @Input() calenderEvent: TYPE.CalendarEvent = {} as TYPE.CalendarEvent;
  @Output() edit: EventEmitter<TYPE.CalendarEvent> = new EventEmitter<TYPE.CalendarEvent>();
  @Output() participants: EventEmitter<TYPE.CalendarEvent> = new EventEmitter<TYPE.CalendarEvent>();

  private dialogInstance: Dialog | null = null;

  constructor(
    private languageService: LanguageService,
  ) {
  }

  public ngOnInit(): void {
  }

  public editEvent(): void {
    this.edit.emit(this.calenderEvent);
  }

  public shareEvent(): void {
    // TODO SHARE EVENT
  }

  public participantsEvent(): void {
    this.participants.emit(this.calenderEvent);
  }

  public showDeleteOverlay(): void {
    this.dialogInstance = DialogUtility.confirm({
      title: '<h6 class="text-danger fw-bold m-0 pt-1">' + this.languageService.getTranslationByKey('CONFIRMITEMDELETE') + '</h6>',
      content: this.languageService.getTranslationByKey('AREYOUSUREYOUWANTTODELETE'),
      cancelButton: {
        text: this.languageService.getTranslationByKey('CANCEL'),
        click: () => this.showDeleteOverlayCancel()
      },
      okButton: {
        text: this.languageService.getTranslationByKey('DELETE'),
        click: () => this.showDeleteOverlayConfirmed(),
        cssClass: 'bg-danger brd-danger'
      },
      showCloseIcon: true,
      closeOnEscape: true,
      position: {X: 'center', Y: 'center'},
      animationSettings: {effect: 'Fade'},
      zIndex: 9999
    });
  }

  private showDeleteOverlayConfirmed(): void {
    // TODO DELETE EVENT
    this.dialogInstance?.hide();
  }

  private showDeleteOverlayCancel(): void {
    this.dialogInstance?.hide();
  }
}
