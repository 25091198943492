<ejs-dialog target=".dialogs" class="simple-dialog" [visible]='visible' [animationSettings]='dialogService.getAnimationSettings()' [resizeHandles]='dialogService.getResizeHandleDirection()' [position]='dialogService.getPosition()' [isModal]="true" [allowDragging]="false" [width]="'30%'" [height]="'60vh'" [zIndex]="999" [showCloseIcon]="true" (close)="close()">
  <ng-template #header>
    <div class="d-flex align-items-center justify-content-center">
      <app-headline [title]="('CUSTOMINFORMATION' | translate)" [type]="'h5'" [class]="'text-primary'"></app-headline>
      <div class="ms-2">
        <app-tooltip [icon]="'help'" [text]="('CUSTOMINFORMATIONTOOLTIP' | translate)" [width]="'300px'" [height]="'100px'" [cssClass]="'helpTooltip p-2'"></app-tooltip>
      </div>
    </div>
  </ng-template>
  <ng-template #content>
    <div class="d-flex flex-column col-12 mx-auto pt-4 p-3 custom-scrollbar h-100 overflow-y-auto">
      <ng-container *ngFor="let number of [].constructor(11); let i = index">
        <ng-container *ngIf="i > 0 && languageService.getTranslationByKey('INFO_' +  (i < 10 ? '0' + i : i)) !== 'INFO_' +  (i < 10 ? '0' + i : i)">
          <div class="row mb-1">
            <div class="col-md-12">
              <app-form-label [label]="('INFO_' + (i < 10 ? '0' + i : i)) | translate"></app-form-label>
              <app-form-input [placeholder]="('INFO_' + (i < 10 ? '0' + i : i)) | translate" [fCN]="'info_' + (i < 10 ? '0' + i : i)" [fG]="fG"></app-form-input>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-template>
  <ng-template #footerTemplate>
    <div class="d-flex align-items-center justify-content-center p-2">
      <app-form-button [text]="'SAVE' | translate" [disabled]="fG.invalid" (click)="save()" [cssClass]="'btn btn-primary mx-auto w-50'"></app-form-button>
    </div>
  </ng-template>
</ejs-dialog>
