import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormGroup} from "@angular/forms";
import {DialogService} from "../../../shared/services/dialog.service";
import {DialogModule} from "@syncfusion/ej2-angular-popups";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {FormInputComponent} from "../../../shared/elements/form-input/form-input.component";
import {FormLabelComponent} from "../../../shared/elements/form-label/form-label.component";
import {NgForOf, NgIf} from "@angular/common";
import {LanguageService} from "../../../shared/services/language.service";

@Component({
  selector: 'app-dialog-profile-information',
  standalone: true,
  imports: [
    DialogModule,
    HeadlineComponent,
    TooltipComponent,
    TranslatePipe,
    FormButtonComponent,
    FormInputComponent,
    FormLabelComponent,
    NgForOf,
    NgIf
  ],
  templateUrl: './dialog-profile-information.component.html',
  styleUrl: './dialog-profile-information.component.scss'
})
export class DialogProfileInformationComponent implements OnInit {
  @Input() visible: boolean = false;
  @Input() fG: FormGroup = new FormGroup({});
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() fGChange: EventEmitter<FormGroup> = new EventEmitter<FormGroup>();
  @Output() saveEvent: EventEmitter<null> = new EventEmitter<null>();

  constructor(
    public dialogService: DialogService,
    public languageService: LanguageService
  ) {
  }

  public ngOnInit(): void {
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

  public save(): void {
    this.saveEvent.emit();
    this.visibleChange.emit(false);
  }
}
