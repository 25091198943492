import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges} from '@angular/core';
import {TYPE} from "../../../shared/types/types";
import {DialogModule, TooltipModule} from "@syncfusion/ej2-angular-popups";
import {StepHeaderComponent} from "../../../shared/elements/step-header/step-header.component";
import {DialogService} from "../../../shared/services/dialog.service";
import {LanguageService} from "../../../shared/services/language.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {FormButtonComponent} from "../../../shared/elements/form-button/form-button.component";
import {FormDateTimeComponent} from "../../../shared/elements/form-date-time/form-date-time.component";
import {FormInputComponent} from "../../../shared/elements/form-input/form-input.component";
import {FormLabelComponent} from "../../../shared/elements/form-label/form-label.component";
import {FormRadioComponent} from "../../../shared/elements/form-radio/form-radio.component";
import {FormUserGroupMailComponent} from "../../../shared/elements/form-user-group-mail/form-user-group-mail.component";
import {HeadlineComponent} from "../../../shared/elements/headline/headline.component";
import {NgIf, NgSwitch, NgSwitchCase} from "@angular/common";
import {StepContentComponent} from "../../../shared/elements/step-content/step-content.component";
import {TooltipComponent} from "../../../shared/elements/tooltip/tooltip.component";
import {TranslatePipe} from "../../../shared/pipes/translate.pipe";
import {DateTimePickerModule} from "@syncfusion/ej2-angular-calendars";
import {DropDownListModule} from "@syncfusion/ej2-angular-dropdowns";
import {RadioButtonModule} from "@syncfusion/ej2-angular-buttons";
import {TextBoxModule} from "@syncfusion/ej2-angular-inputs";

@Component({
  selector: 'app-dialog-dashboard-link',
  standalone: true,
  imports: [
    DialogModule,
    StepHeaderComponent,
    FormButtonComponent,
    FormDateTimeComponent,
    FormInputComponent,
    FormLabelComponent,
    FormRadioComponent,
    FormUserGroupMailComponent,
    HeadlineComponent,
    NgIf,
    NgSwitchCase,
    ReactiveFormsModule,
    StepContentComponent,
    TooltipComponent,
    TranslatePipe,
    DateTimePickerModule,
    DropDownListModule,
    RadioButtonModule,
    TextBoxModule,
    TooltipModule,
    NgSwitch
  ],
  templateUrl: './dialog-dashboard-link.component.html',
  styleUrl: './dialog-dashboard-link.component.scss'
})
export class DialogDashboardLinkComponent implements OnInit, OnChanges {

  @Input() dashboardLink: TYPE.DashboardLink = {} as TYPE.DashboardLink;
  @Input() visible: boolean = false;
  @Output() visibleChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public steps: string[] = [this.languageService.getTranslationByKey('DASHBOARDLINKDETAILS'), this.languageService.getTranslationByKey('DASHBOARDLINKPROPERTIES'), this.languageService.getTranslationByKey('DASHBOARDLINKSHARE')];
  public activeStep: number = 0;
  public dashboardLinkForm: FormGroup = this.formBuilder.group({
    name: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(35)]],
    url: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(35)]],
    type: ['', [Validators.required]],
    platform: ['', [Validators.required]],
    dateTimeStart: ['', [Validators.required]],
    dateTimeEnd: ['', [Validators.required]]
  });
  public participantsUserIDs: number[] = [108];
  public participantsGroupIDs: number[] = [8, 6];

  constructor(
    public dialogService: DialogService,
    private languageService: LanguageService,
    private formBuilder: FormBuilder
  ) {
  }

  public ngOnInit(): void {
    this.dashboardLinkForm.valueChanges.subscribe((value: any) => {

    });
  }

  public ngOnChanges(changes: SimpleChanges): void {

  }

  public save(): void {
    console.log('Save');
    this.visibleChange.emit(false);
  }

  public close(): void {
    this.visibleChange.emit(false);
  }

}
