import {Component, ElementRef, OnDestroy, OnInit, ViewChild,HostListener, SimpleChanges} from '@angular/core';
import {debounceTime, distinctUntilChanged, Subscription, switchMap} from "rxjs";
import {TranslatePipe} from "../../shared/pipes/translate.pipe";
import {ActivatedRoute} from "@angular/router";
import {CustomerService} from "../../shared/services/customer.service";
import {UserService} from "../../shared/services/user.service";
import {ApiService} from "../../shared/services/api.service";
import {RouterService} from "../../shared/services/router.service";
import {FormBuilder, FormGroup, ReactiveFormsModule, Validators} from "@angular/forms";
import {Config} from "../../shared/config/config";
import {AsyncPipe, NgIf, NgOptimizedImage} from "@angular/common";
import {FormInputComponent} from "../../shared/elements/form-input/form-input.component";
import {ErrorService} from "../../shared/services/error.service";
import {LanguageService} from "../../shared/services/language.service";
import {LoadingService} from "../../shared/services/loading.service";
import {LayoutService} from "../../shared/services/layout.service";
import {FormButtonComponent} from "../../shared/elements/form-button/form-button.component";
import {LinkComponent} from "../../shared/elements/link/link.component";
import {FormTextareaComponent} from "../../shared/elements/form-textarea/form-textarea.component";
import {HeadlineComponent} from "../../shared/elements/headline/headline.component";
import {TextBoxModule, NumericTextBoxModule, TextAreaModule, InputEventArgs, ChangedEventArgs, UploaderModule, SelectedEventArgs, FileInfo, RemovingEventArgs, UploaderComponent} from '@syncfusion/ej2-angular-inputs';
import {TooltipModule,TooltipComponent} from "@syncfusion/ej2-angular-popups";
import { ListViewModule, ListViewComponent } from '@syncfusion/ej2-angular-lists';


import { DialogUtility,createSpinner, showSpinner, hideSpinner } from '@syncfusion/ej2-popups';
import { DialogModule } from '@syncfusion/ej2-angular-popups';
import { DialogComponent, ResizeDirections } from '@syncfusion/ej2-angular-popups';
import { CommonModule } from '@angular/common';
import { DropDownListModule, FilteringEventArgs, ListBoxModule, ListBoxComponent,MultiSelectModule, RemoveEventArgs, MultiSelectChangeEventArgs, MultiSelectComponent, ActionBeginEventArgs, DropDownListComponent} from '@syncfusion/ej2-angular-dropdowns';
import { DatePickerModule, TimePickerModule,DateTimePickerModule } from '@syncfusion/ej2-angular-calendars';
import { ButtonModule, RadioButtonModule, CheckBoxModule, SwitchModule, FabModule } from '@syncfusion/ej2-angular-buttons';
import { EmitType, L10n, setCulture } from '@syncfusion/ej2-base';
import { DataManager, Query } from '@syncfusion/ej2-data';
import {FormCheckboxComponent} from "../../shared/elements/form-checkbox/form-checkbox.component";
import {FormDropdownComponent} from "../../shared/elements/form-dropdown/form-dropdown.component";
import { arrayValidator } from '../../shared/validators/array.directive';
import {FormLabelComponent} from "../../shared/elements/form-label/form-label.component";


interface UserGroup {
  id: number;
  name: string;
}

@Component({
    selector: 'app-test1',
    standalone: true,
  imports: [
    AsyncPipe,
    FormButtonComponent,
    FormInputComponent,
    FormTextareaComponent,
    HeadlineComponent,
    LinkComponent,
    NgIf,
    NgOptimizedImage,
    ReactiveFormsModule,
    TranslatePipe,
    ButtonModule,
    TextBoxModule,
    TooltipModule,
    ListViewModule,
    DialogModule,
    CommonModule,
    DropDownListModule,
    DatePickerModule,
    TimePickerModule,
    NumericTextBoxModule,
    RadioButtonModule,
    DateTimePickerModule,
    CheckBoxModule,
    TextAreaModule,
    FormCheckboxComponent,
    ListBoxModule,
    SwitchModule,
    FormDropdownComponent,
    FormLabelComponent,
    FabModule,
    UploaderModule,
    MultiSelectModule,
  ],
    templateUrl: './test1.component.html',
    styleUrl: './test1.component.scss'
  })

  
  export class TestComponent1 implements OnInit, OnDestroy {
    public fileFolderFilter: { [key: string]: Object }[] = [
      { name: 'Both', id: '1' },
      { name: 'File Name', id: '2' },
      { name: 'Folder Name', id: '3' }
    ];

    //Small Filter dropdown for File Type Filter
    public fileTypeFilter: { [key: string]: Object }[] = [
      { text: 'All Files', id: 'all'},
      { text: 'Templates', id: 'templates' },
      { text: 'Images', id: 'images' },
      { text: 'Videos', id: 'videos' },
      { text: 'Microsoft Word', id: 'word' },
      { text: 'Microsoft Excel', id: 'excel' },
      { text: 'PDF', id: 'pdf' },
      { text: 'Other', id: 'other' },
      
    ];
    public fileTypeFilterFields: Object = { text: 'text', value: 'id' };
    public fileTypeFilterSelectedValue: string = 'all';

    //Small Filter dropdown for File Type Filter
    public fileFolderTypeFilter: { [key: string]: Object }[] = [
      { text: 'All', id: 'all'},
      { text: 'Only Folders', id: 'folders' },
      { text: 'Only Files', id: 'files' },    
    ];
    public fileFolderTypeFilterFields: Object = { text: 'text', value: 'id' };
    public fileFolderTypeFilterSelectedValue: string = 'all';

    public loginForm: FormGroup = this.formBuilder.group({
      companyName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(60)]],
      salutation: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(10)]],
      firstName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(40)]],
      lastName: ['', [Validators.required, Validators.minLength(1), Validators.maxLength(40)]],
      info_01: [''],
      info_02: [''],
      mobileNumber: [''],
      emailAddress: [''],
      telephone: [''],
      search_string: [''],
      search_user_string: [''],
      external_email: [''],
      rm_1: [''],
      search_link_string: [''],
      mobiles: [''],
      desktops: [''],
      filter_options: ['', [Validators.required, arrayValidator(Object.keys(this.fileFolderFilter))]],
      priority: [''],
      image_link: [''],
      password: [''],
      user_name: [''],
      position: [''],
      company: ['']
    });
 
    public defaultStoreString = Config.DEFAULT_STORE_STRING;
    private subscriptions: Subscription[] = [];

    @ViewChild('helpTooltip', { static: true }) helpTooltip: TooltipComponent | any;
    @ViewChild('myFilesDialog') myFilesDialog: DialogComponent | any;
    @ViewChild('showChangeAvatar') showChangeAvatar: DialogComponent | any;
    @ViewChild('showFullScreenDialog') showFullScreenDialog: DialogComponent | any;
    @ViewChild('showChangeCredentials') showChangeCredentials: DialogComponent | any;
    @ViewChild('showCustomInformations') showCustomInformations: DialogComponent | any;

    
    public position: object={ X: 'center', Y: 'center' };
    public animationSettings: Object = { effect: 'Fade', duration: 400, delay: 0 };
    public resizeHandleDirection: ResizeDirections[] = ['All'];

    /**** FULE UPLOAD */
    @ViewChild('fileUpload', { static: false }) public uploadObj!: UploaderComponent;

    public path: Object = {
        saveUrl: 'API FOR SAVE'
    };

    public allowExtensions: string = '.png, .jpg, .jpeg';

    public dropElement!: HTMLElement;
    public filesDetails: any[] = [];
    public progressValue: number = 0;
    public fileUploadFailed: boolean = false;

    visibility=false;

    constructor(
        public loadingService: LoadingService,
        private apiService: ApiService,
        private customerService: CustomerService,
        private errorService: ErrorService,
        private formBuilder: FormBuilder,
        private languageService: LanguageService,
        private route: ActivatedRoute,
        private routerService: RouterService,
        private userService: UserService,
        public layoutService: LayoutService,
      ) {

      }

      
        public ngOnInit(): void {

        }

        public ngAfterViewInit(): void {
          
        }

        @HostListener('window:resize')
        onResize() {
          if (this.helpTooltip) {
            this.helpTooltip.refresh(); // Refresh tooltip position on window resize
          }
        }

        public ngOnDestroy(): void {
            for (const subscription of this.subscriptions) {
              subscription.unsubscribe();
            }
            window.removeEventListener('resize', this.onResize);
        }

        public onFakeClick(){
          console.log("Clicked item");

        }
        onOpenDialog = (event: any, id:string): void => {
            event.preventDefault();
            if (id == 'change-avatar') {
              this.showChangeAvatar!.show();
              this.dropElement = document.getElementsByClassName('fileUploadArea')[0] as HTMLElement;
            }

            if (id == 'change-credentials') {
              this.showChangeCredentials!.show();
            }

            if (id == 'show-custominfo') {
              this.showCustomInformations!.show();
            }

            if (id == 'my-files') {
              this.myFilesDialog!.show();
            }
            if (id == 'preview-image') {
              this.showFullScreenDialog!.show();
            }
        }

        closeDialog(id: string = ""): void {
          if (id == 'change-avatar') {
            this.showChangeAvatar!.hide();
          }

          if (id == 'change-credentials') {
            this.showChangeCredentials!.hide();
          }

          if (id == 'my-files') {
            this.myFilesDialog!.hide();
          }

          if (id == 'preview-image') {
            this.showFullScreenDialog!.hide();
          }

          if (id == 'show-custominfo') {
            this.showCustomInformations!.hide();
          }
        }

        public onDropdownItemClick(selectedItem: any): void {
          console.log('Selected item:', selectedItem);
        }

        /**** FILE UPLOAD ****/

        public onChooseFileClicked(args: any): void{
          args.preventDefault();
          document.getElementsByClassName('e-file-select-wrap')[0].querySelector('button')?.click();
        }

        // Handle file selection (drag-and-drop or browse)
        public onSelect(args: SelectedEventArgs): void {
          const validFiles = this.validateFiles(args);
          // No valid files
          if (validFiles.length === 0) {
            args.cancel = true; 
            return;
          }

          // Process each valid file to generate preview
          validFiles.forEach((file) => {
            const rawFile = file.rawFile as File;
            const reader = new FileReader();
            reader.onload = (event) => {
              // Push file data with preview URL
              this.filesDetails.push({
                id: file.id,
                name: file.name,
                size: this.formatFileSize(file.size),
                preview: (event.target as FileReader).result,
                file: file, // Original FileInfo object
              });
            };
            reader.readAsDataURL(rawFile);
          });

          // Prevent Syncfusion from Auto Upload
          args.cancel = true; 
        }

        // Validate selected files
        public validateFiles(args: SelectedEventArgs): FileInfo[] {
          const validFiles: FileInfo[] = [];
          // Check for allowed extensions and duplicates
          args.filesData.forEach((file) => {
            if (
              this.allowExtensions.includes(file.type) &&
              !this.filesDetails.some((existingFile) => existingFile.name === file.name)
            ) {
              validFiles.push(file);
            }
          });
          console.log(validFiles);
          return validFiles;
        }


        public onUploadFailed(args : any) : void {
          this.fileUploadFailed=true;
          this.progressValue=0;
          // Remove the file from the preview list if the upload fails
          this.filesDetails = this.filesDetails.filter(
            (file) => file.id !== args.file.id
          );
        }

        public onFileUploadProgress(args: any): void {
          const progress = Math.round((args.e.loaded / args.e.total) * 100);
          this.progressValue = progress;
        }
      
        public onUploadComplete(): void {
          this.fileUploadFailed=false;
          this.progressValue = 100;
          setTimeout(() => (this.progressValue = 0), 2000); 
        }

        private loadImagesFromServer(): void {
         /* this.http.get<any[]>(this.apiUrl).subscribe(
            (data) => {
              // Map server data to filesDetails
              this.filesDetails = data.map((item) => ({
                id: item.id, 
                name: item.name, 
                preview: item.url, 
                size: item.size || 0, 
                status: 'Uploaded', 
              }));
            },
            (error) => {
              console.error('Error loading images from server:', error);
            }
          );*/
        }


        public removeFile(file: any, event: Event): void {
          // Remove from the local preview list
          this.filesDetails = this.filesDetails.filter((f) => f.id !== file.id);
          // If file has been uploaded, remove it from the server
          console.log(file);
        }

        public moveFileLeft(file: any, event: Event): void {
          event.stopPropagation();
          const index = this.filesDetails.indexOf(file);
          if (index > 0) {
            const temp = this.filesDetails[index - 1];
            this.filesDetails[index - 1] = file;
            this.filesDetails[index] = temp;
          }
        }
        
        public moveFileRight(file: any, event: Event): void {
          event.stopPropagation();
          const index = this.filesDetails.indexOf(file);
          if (index < this.filesDetails.length - 1) {
            const temp = this.filesDetails[index + 1];
            this.filesDetails[index + 1] = file;
            this.filesDetails[index] = temp;
          }
        }

        // Format file size for display
        public formatFileSize(size: number): string {
          const kb = size / 1024;
          return kb > 1024 ? (kb / 1024).toFixed(2) + ' MB' : kb.toFixed(2) + ' KB';
        }

        public onFileClick(file: FileInfo): void {
          console.log("File clicked:", file.name);
          
        }

        /***** IMAGE PREVIEW POPUPS */

        showLinkPopup: boolean = false;
        showInfoPopup: boolean = false;

        togglePopup(type: 'link' | 'info'): void {

          if (type === 'link') {
            this.showLinkPopup = !this.showLinkPopup;
            this.showInfoPopup = false;
          } else if (type === 'info') {
            this.showInfoPopup = !this.showInfoPopup;
            this.showLinkPopup = false;
          }
        }

        public onFakePreviewClick(e: any){
          e.stopPropagation();
          console.log("Preview image");
        }

        public onCustomerLogin(customerKey: string | null): void {
            if (customerKey) {
              this.subscriptions.push(this.apiService.getCustomer(customerKey).subscribe({
                next: (customer) => {
                  this.customerService.setCustomer(customer);
                  this.languageService.setCustomerLanguages(customer.languages);

                  if (customer.api && typeof customer.api != null) {
                    this.apiService.setUrl(customer.api);
                  }

                  if (this.route.snapshot.params.hasOwnProperty('token')) {
                    this.userService.setToken(this.route.snapshot.params['token']);
                  }
                  this.routerService.navigateTo('/auth/login');
                }, error: (error) => {
                  this.errorService.setError(this.languageService.getTranslationByKey('ERROR'), error, true, false);
                }
              }));
            }
          }

          public setLanguage(language: string): void {
            this.languageService.setLanguage(language);
          }
        }

